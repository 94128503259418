import { FormLabel, TextField } from '@material-ui/core'
import { Button, FormGroup } from '@material-ui/core'
import React, { useState } from 'react'
import NewLoader from '../Common/NewLoader'
import GlobalModal from '../globals/Modal/GlobalModal'

export default function SearchParameter(props){

    const [neighbourCount, setNeighbourCount] = useState(1)
    const [maximumDistance, setMaximumDistance] = useState(50)
    const [submitting, setSubmitting] = useState(false)

    const changeNeighbourCount=(event)=>{
        if(event.target.value ==='' || (event.target.value >=0 && event.target.value <= 500 && !submitting)){
            setNeighbourCount(event.target.value)
        }
    }

    const changeMaximumDistance=(event)=>{
        if(event.target.value ==='' || (event.target.value >=0 && event.target.value <= 500 && !submitting)){
            setMaximumDistance(event.target.value)
        }
    }

    const searchParameter=()=>{

        if(neighbourCount <1 && maximumDistance <1 && !submitting){
            window.showNotification('ERROR',"Neighbour count or Maximum Distance can not be zero or less")
        }
        if(neighbourCount !== '' && maximumDistance !== '' && !submitting ){
            if(props.access){
                setSubmitting(true)
            }
            props.searchingParameter(neighbourCount, maximumDistance)
        }
    }


    return (
        <React.Fragment>
            <GlobalModal {...props}
				placement="right" 
			>
                <NewLoader loading={submitting} onlyLoader={true} size={26} message={process.env.LOADER_ICONTEXT} />
            <div className="ppc__search_modal_wrapper">
                <form>
                    <FormGroup>
                        <FormLabel className="mb-2">Neighbors Count</FormLabel>
                        <TextField
                            id="outlined-secondary" 
                            variant="outlined"
                            color="secondary"
                            type="number"
                            value={neighbourCount}
                            onChange={changeNeighbourCount}
                          />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel className="my-2">Maximum Distance</FormLabel>
                        <TextField
                            id="outlined-secondary" 
                            variant="outlined"
                            color="secondary"
                            type="number"
                            value={maximumDistance}
                            onChange={changeMaximumDistance}
                          />
                    </FormGroup>
                    <span className="distance_text">Distance Measurement unit is Meter</span>
                    <div className="d-flex align-align-items-center justify-content-center">
                        <Button className="ppc__blue_btn search__contracts accent--bg--color w-100" onClick={searchParameter}>Search the Parameter</Button>
                    </div>
                
                </form>
            </div>
            </GlobalModal>
        </React.Fragment>
    )
}