import {
  Box,
  Dialog,
  DialogContent,
  FormControl,
  MenuItem,
  Select,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import { IconList } from "../../../../constants/IconList";
import { Close as CloseIcon } from "@material-ui/icons";
import { BasicInputSelect } from "../ContactDetailsStyle";
import ModalAssignToList from "../../enhanceDataAction/ModalAssignToList";
import ModalAssignToTagNew from "../../enhanceDataAction/ModalAssignToTag";
import ModalListForStageAdd from "../../enhanceDataAction/ModalListForStageAdd";
import ModalAssignToCampaign from "../../enhanceDataAction/ModalAssignToCampaign";
import ModalAssignToPowerDialer from "../../enhanceDataAction/ModalAssignToPowerDialer";
import { Button } from "reactstrap";
import { saveContactFromMapSidebar } from "../../../../api/mapApi";

const useStyles = makeStyles({
  modalWrapper: {
    display: "grid",
    placeContent: "center",
  },
  selectMenuStyle: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  headerTitle: {
    margin: 0,
    fontFamily: " Poppins, Arial, sans-serif",
    fontSize: "18px",
    lineHeight: "28px",
    letterSpacing: "0.15px",
    color: "#1c1b1f",
    fontWeight: 500,
  },
  modalHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px 17px",
    borderBottom: "1px solid rgba(148, 157, 178, 0.24)",
    paddingRight: "6px",
  },

  headerClose: {
    height: "40px",
    width: "40px",
    borderRadius: "50%",
    display: "grid",
    placeContent: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(237, 0, 57, 0.03)",
    },
  },
  saveButton: {
    background: "#006df5 !important",
    color: "white !important",
    "&:hover": {
      background: "#006df5 !important",
    },
  },
  closeButton: {
    background: "#d53d3d !important",
    color: "white !important",
    "&:hover": {
      background: "#d53d3d !important",
    },
  },
});

const SelectActionForContactScreen = (props) => {
  const [selectedAction, setSelectedAction] = useState(0);
  const {
    state,
    markerContactDetails,
    handleCloseModal,
    setIsOpenActionModal,
  } = props;

  const [openAddToListModal, setOpenAddToListModal] = useState(false);
  const [openAddToTagModal, setOpenAddToTagModal] = useState(false);
  const [openAddToStageModal, setOpenAddToStageModal] = useState(false);
  const [openAddToCampaignModal, setOpenAddToCampaignModal] = useState(false);
  const [openAddToPowerDialerModal, setOpenAddToPowerDialerModal] =
    useState(false);
  const [openAddToConnectCRMModal, setOpenAddToConnectCRMModal] =
    React.useState(false);
  const [disableSaveButton, setDisableSetButton] = useState(false);

  const handleAddContact = async () => {
    setDisableSetButton(true);
    const payload = { contactDetails: markerContactDetails };
    try {
      await saveContactFromMapSidebar(payload)
        .then((res) => {
          if (res && res.data.success) {
            props.addSuccess(res.data.data.data, true);
            window.showNotification("success", res.data.message);
            closeAddCrmModal();
            handleCloseModal();
          } else {
            window.showNotification(
              "error",
              "Something went wrong try again later"
            );
          }
        })
        .finally(() => {});
    } catch (e) {}
  };
  const classes = useStyles();
  const handleAddToConnectModalOpen = () => {
    setOpenAddToConnectCRMModal(true);
    selectCloseActionModal();
  };
  const closeAddCrmModal = () => {
    setOpenAddToConnectCRMModal(false);
  };
  const selectCloseActionModal = () => {
    setIsOpenActionModal(false);
  };
  return (
    <>
      {props.isOpenActionModal && (
        <Dialog
          open={props.isOpenActionModal}
          onClose={selectCloseActionModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent style={{ width: "600px", padding: 0 }}>
            <Box className={classes.modalHeader}>
              <Typography style={{ fontWeight: 600 }} variant="h5">
                Select An Action
              </Typography>
              <Box
                className={classes.headerClose}
                onClick={selectCloseActionModal}
              >
                <CloseIcon color="error" />
              </Box>
            </Box>

            <Box padding={"16px"}>
              <FormControl fullWidth>
                <Select
                  style={{
                    "&.MuiSelect-select": {
                      display: "flex !important",
                      alignItems: "center !important",
                      gap: "10px !important",
                    },
                  }}
                  name="selectAction"
                  value={selectedAction}
                  displayEmpty
                  input={
                    <BasicInputSelect padding={"14px 12px 14px !important"} />
                  }
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  <MenuItem
                    className={`dropdownhelper-menuitem-class ${classes.selectMenuStyle}`}
                    value={0}
                    disabled
                  >
                    Select an action
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleAddToConnectModalOpen();
                    }}
                    className={`dropdownhelper-menuitem-class ${classes.selectMenuStyle}`}
                  >
                    <span>{IconList.addToContactSVG}</span> Add Contact To CRM
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      selectCloseActionModal();
                      setOpenAddToListModal(true);
                    }}
                    className={`dropdownhelper-menuitem-class ${classes.selectMenuStyle}`}
                  >
                    <span>{IconList.addToListSVG}</span> Add To List
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      selectCloseActionModal();
                      setOpenAddToCampaignModal(true);
                    }}
                    className={`dropdownhelper-menuitem-class ${classes.selectMenuStyle}`}
                  >
                    <span>{IconList.addToContactSVG}</span> Add To Campaign
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      selectCloseActionModal();
                      setOpenAddToTagModal(true);
                    }}
                    className={`dropdownhelper-menuitem-class ${classes.selectMenuStyle}`}
                  >
                    <span>{IconList.addTagSVG}</span> Assign Tag
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      selectCloseActionModal();
                      setOpenAddToStageModal(true);
                    }}
                    className={`dropdownhelper-menuitem-class ${classes.selectMenuStyle}`}
                  >
                    <span>{IconList.addToPipelineSVG}</span> Add to Pipeline
                    Stage
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      selectCloseActionModal();
                      setOpenAddToPowerDialerModal(true);
                    }}
                    className={`dropdownhelper-menuitem-class ${classes.selectMenuStyle}`}
                  >
                    <span>{IconList.addToContactSVG}</span> Add To Power Dialer
                    List
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </DialogContent>
        </Dialog>
      )}
      {openAddToConnectCRMModal && (
        <Dialog
          open={openAddToConnectCRMModal}
          onClose={closeAddCrmModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent style={{ width: "400px" }}>
            <Typography
              variant="h5"
              align="center"
              style={{ fontWeight: "600" }}
            >
              Do you want to connect the contact to CRM ?
            </Typography>
          </DialogContent>
          <Box
            display={"flex"}
            justifyContent={"center"}
            py={2}
            style={{ gap: "16px" }}
          >
            <Button
              onClick={handleAddContact}
              variant="contained"
              className={classes.saveButton}
            >
              {disableSaveButton ? "Saving..." : "Yes"}
            </Button>

            <Button
              onClick={closeAddCrmModal}
              className={classes.closeButton}
              variant="contained"
            >
              Cancel
            </Button>
          </Box>
        </Dialog>
      )}
      {openAddToListModal && (
        <ModalAssignToList
          open={openAddToListModal}
          onClose={() => setOpenAddToListModal(false)}
          markerContactDetails={markerContactDetails}
          handleCloseModal={handleCloseModal}
        />
      )}
      {openAddToTagModal && (
        <ModalAssignToTagNew
          open={openAddToTagModal}
          onClose={() => setOpenAddToTagModal(false)}
          markerContactDetails={markerContactDetails}
          handleCloseModal={handleCloseModal}
        />
      )}

      {openAddToStageModal && (
        <ModalListForStageAdd
          open={openAddToStageModal}
          onClose={() => setOpenAddToStageModal(false)}
          markerContactDetails={markerContactDetails}
          handleCloseModal={handleCloseModal}
        />
      )}

      {openAddToCampaignModal && (
        <ModalAssignToCampaign
          state={state}
          markerContactDetails={markerContactDetails}
          open={openAddToCampaignModal}
          onClose={() => setOpenAddToCampaignModal(false)}
          handleCloseModal={handleCloseModal}
        />
      )}

      {openAddToPowerDialerModal && (
        <ModalAssignToPowerDialer
          open={openAddToPowerDialerModal}
          markerContactDetails={markerContactDetails}
          onClose={() => setOpenAddToPowerDialerModal(false)}
          handleCloseModal={handleCloseModal}
        />
      )}
    </>
  );
};

export default SelectActionForContactScreen;
