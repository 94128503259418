/* global google */
import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
	CheckBox,
	Search,
	ErrorOutline,
	Forward,
} from "@material-ui/icons";
import { FormGroup } from "reactstrap";
import { Button, FormControl, Select } from "@material-ui/core";
import GoogleMap from "google-map-react";
import Checkbox from "@material-ui/core/Checkbox";
import ReactDOMServer from "react-dom/server";

import "./customMap.css";
import GlobalModal from "../globals/Modal/GlobalModal";
import ActionModalComponent from "./ActionModalComponent";
import SearchParameter from "./SearchParameter";
import TagModalComponent from "./TagModalComponent";
import {
	fetchAllContactsPositionArray,
	fetchUserDispositionList,
} from "../../actions/mapAction";
import {
	fetchContactInfoByLatlng,
	fetchContactsWithSpecificDisposition,
	fetchMapFinderData,
	getMapDataWithDisposition,
	getSingleContactFromMap,
	loadContactsByTag,
	mapOrderNameEdit,
	checkMapPackageExist,
	changeContactDisposition,
	getUserMysPackageStatus,
} from "../../api/mapApi";
// import Marker from './Marker';
import MarkerClusterer from "@googlemaps/markerclustererplus";
import SelectAction from "./SelectAction";
import SendingProcess from "./SendingProcess";
import SetOrderName from "./SetOrderName";
import InstanceSendComponent from "./InstanceSendComponent";
import ContactDetails from "./ContactDetails";
import LeadProcess from "./LeadProcess";
import Helper from "../globals/Helper";
import { makeStyles } from "@material-ui/core/styles";


import clsx from "clsx";
import { green } from "@material-ui/core/colors";
import { iconList } from "../globals/IconList";
import NewLoader from "../Common/NewLoader";
import ShowSelectedDisposition from "./ShowSelectedDisposition";
import Utils from "../../helpers/Utils";
import SmsSendModal from "../contactDetails/modals/SendModules/SmsSend";
import EmailSendModal from "../contactDetails/modals/SendModules/EmailSend";
import VideoEmailModal from "../Video/VideoEmailModal";
import VoiceSendModal from "../contactDetails/modals/SendModules/VoiceSend";
import DripModeModal from "./DripModeModal";
import {
	_updateContactDetails,
	_updateContactId,
} from "../contactDetails/redux/contactDetailAction";
import AllInfoModal from "./AllInfoModal";
import CardMultiStepForm from "../DirectCardSend/CardMultiStepForm";
import { HistoryProvider } from '../../App';
import InformationPopup from "./InformationPopup";
import PauseAlert from "./mapV2/PauseAlert";
import ContactDetailsV2 from "./contactDetailsV2";


var autocomplete;
var places;
var selectedShape;
var countryRestrict = { country: "us" };
var markerCluster;
var activeInfoWindow;
var infoWindow; //To show tool tip text on draw.
var loadedMarkers = [];
var map;
let geocoder;
var dispositionMarkers = [];

const POSTCARD = 6;
const GREETING_CARD = 7;
const GIFT = 8;
const LETTER = 9;

let notificationCount = 0;

const LOAD_ALL_CONTACTS = 1;
const LOAD_TAGS = 2;
const EXCLUDE_TAGS = 3;
const PREVIEW_DATA = 4;
const SHOW_DISPOSITION = 5;
let previewDataShowtemp = false;

let drawingManager;

const SENDMESSAGE = "1";
const ADDTOCAMPAIGN = "2";
const CREATELIST = "3";

const PROCESSALLATONCE = "11";
const PROCESSINDRIPMODE = "12";

const STARTFROMSCRATCH = 1;
const QUICKDELIVERY = 2;

let deviceHeight = window.outerHeight;

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(2),
		minWidth: 120,
		maxWidth: 300,
	},
	chips: {
		display: "flex",
		flexWrap: "wrap",
	},
	chip: {
		margin: 2,
	},
	noLabel: {
		marginTop: theme.spacing(3),
	},
	//For Loader
	root: {
		display: "flex",
		alignItems: "center",
	},
	wrapper: {
		margin: theme.spacing(1),
		position: "relative",
	},
	buttonSuccess: {
		backgroundColor: green[500],
		"&:hover": {
			backgroundColor: green[700],
		},
	},
	fabProgress: {
		color: green[500],
		position: "absolute",
		top: -6,
		left: -6,
		zIndex: 1,
	},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
}));

const loaderContentAppend = ReactDOMServer.renderToString(
	<div className="ppc__search_modal_in_map">
		<NewLoader loading={true} onlyLoader={true} size={25} message={""} />
	</div>
);

const pinningImageContent = (message) => {
	return (
		"<b>" +
		message +
		"</b><br/>" +
		'<img src="' +
		process.env.REACT_APP_APP_URL +
		'/assets/img/disposition/pinning.gif" width="160" class="img-thumbnail"/>'
	);
};

const IndexOld = (props) => {
	const classes = useStyles();
	const addressRef = React.createRef();
	const history = useContext(HistoryProvider);

	//Loader
	// const classes = useStyles();
	const [access, setAccess] = useState(false);
	const [success, setSuccess] = useState(false);
	const timer = React.useRef();

	const buttonClassname = clsx({
		[classes.buttonSuccess]: success,
	});

	React.useEffect(() => {
		return () => {
			clearTimeout(timer.current);
		};
	}, []);

	const [actionModalShow, setActionModalShow] = useState(false);
	const [searchParameterShow, setSearchParameterShow] = useState(false);
	const [tagListShow, setTagListShow] = useState(false);
	const [showSelectAction, setShowSelectAction] = useState(false);
	const [showDripMode, setShowDripMode] = useState(false);
	const [sendingProcess, setSendingProcess] = useState(false);
	const [showOrderName, setShowOrderName] = useState(false);
	const [showInstanceSend, setShowInstanceSend] = useState(false);
	const [showContactDetails, setShowContactDetails] = useState(false);
	const [showDealProcess, setShowDealProcess] = useState(false);

	const [excludeTagListShow, setExcludeTagListShow] = useState(false);
	const [excludeTags, setExcludeTags] = useState([]);
	const [selectedContacts, setSelectedContacts] = useState([]);
	const [loadAllContacts, setLoadAllContacts] = useState(false);
	const [allContactPositionArray, setAllContactPositionArray] = useState(null);
	const [updateMap, setUpdateMap] = useState(false);
	const [previewDataShow, setPreviewDataShow] = useState(false);
	const [clickedMarkerPosition, setClickedMarkerPosition] = useState(null);

	const [loadTagList, setLoadTagList] = useState([]);
	const [dispositionList, setDispositionList] = useState([]);

	const [markerContactDetails, setMarkerContactDetails] = useState(null);
	const [campaignList, setCampaignList] = useState(null);
	const [dispositionInfo, setDispositionInfo] = useState(null);

	const [savedContactDetails, setSavedContactDetails] = useState(null);

	const [mapLeadProcessId, setMapLeadProcessId] = useState("");

	const [showDispositionSelect, setShowDispositionSelect] = useState(false);
	const [selectedDispositionName, setSelectedDispositionName] = useState([]);
	const [selectedDispositionId, setSelectedDispositionId] = useState([]);
	const [update, setUpdate] = useState(false);

	const [openSMSModal, setOpenSMSModal] = useState(false);
	const [openEmailModal, setOpenEmailModal] = useState(false);
	const [openVideoMailModal, setOpenVideoMailModal] = useState(false);
	const [openVoiceMailModal, setOpenVoiceMailModal] = useState(false);

	const [campaignId, setCampaignId] = useState(null);
	//Card submit
	const [viewCardModal, setViewCardModal] = React.useState(false);
	const [activeMailItemType, setActiveMailItemType] = React.useState('');

	//contact details
	const [allInfoModalShow, setAllInfoModalShow] = useState(false);
	const [viewRegistrationModal, setViewRegistrationModal] = useState(false);
	const [viewPauseModal, setViewPauseModal] = useState(false);
	const [isPaused, setIsPaused] = useState(false);
	const [contactLocation, setContactLocation] = useState(null);

	useEffect(() => {

		if (Utils.getAccountData('agencyId') !== 1) {

			if (Utils.getAccountData('isAgencyMapPackageActive') && Utils.getAccountData('isAgencyMapPackageActive') === true) {
				// mapPackageExists();
				mysPackageStatus().then();
			} else {

				window.showNotification('ERROR', "Map Feature is not available")
				history.push({
					pathname: '/',
				});
			}
		} else {
			setAccess(true)
		}

	}, [])

	useEffect(() => {
		if (access) {
			props.fetchUserDisposition();

		}
	}, [access]);

	useEffect(() => {
		setDispositionList(props.storedDispositionList)
	}, [props.storedDispositionList])

	const mapPackageExists = () => {
		checkMapPackageExist()
			.then(res => {
				if (res.data.success) {

					if (res.data.message === 'no-access') {
						setViewRegistrationModal(true)
						// sessionStorage.setItem('mapPackage'+Utils.getAccountData('userId'),res.data.message);
					}
					else if (res.data.message) {
						// sessionStorage.setItem('mapPackage'+Utils.getAccountData('userId'),res.data.message);
						setAccess(true)

					} else {
						setViewRegistrationModal(true)

						// sessionStorage.setItem('mapPackage'+Utils.getAccountData('userId'),res.data.message);
					}
				} else {
					setViewRegistrationModal(true)

					// sessionStorage.setItem('mapPackage'+Utils.getAccountData('userId'),false);
				}
			})
			.catch(e => {
				setViewRegistrationModal(true)

				// sessionStorage.setItem('mapPackage'+Utils.getAccountData('userId'),false);
			})
	}

	const mysPackageStatus = async () => {
		try {
			const res = await getUserMysPackageStatus();
			if (res && res.success) {
				if (res.data.status === "active") {
					setAccess(true);
				} else if (res.data.status === "paused") {
					setViewPauseModal(true);
					setIsPaused(true);
				} else {
					setViewRegistrationModal(true);
				}
			} else {
				setViewRegistrationModal(true);
			}
		} catch (err) {
			setViewRegistrationModal(true);
		}
	}

	IndexOld.showSavedContactDetailsModal = (savedContactId) => {
		props._updateContactId(savedContactId);
		setAllInfoModalShow(true);
	};

	//Change when click in checkboxs
	const handleChange = (index, hasAcess = undefined) => {
		if (index === LOAD_ALL_CONTACTS) {
			console.log("318")
			//If load all contacts not selected before
			if (!loadAllContacts) {
				console.log("321", props.allContactPositionArray)
				setLoadAllContacts(!loadAllContacts);
				IndexOld.clearSelection();
				IndexOld.loadAllContactsFunction(props.allContactPositionArray, hasAcess);
			} else {
				console.log("326")
				IndexOld.clearSelection();
				setLoadAllContacts(!loadAllContacts);
			}
		} else if (index === LOAD_TAGS) {
			if (loadTagList.length > 0) {
				setLoadTagList([]);
			} else {
				setTagListShow(true);
			}
		} else if (index === EXCLUDE_TAGS) {
			if (excludeTags.length > 0) {
				setExcludeTags([]);
			} else {
				setExcludeTagListShow(true);
			}
		}
		// else{
		//     // clearMarkers()
		//     setSearchParameterShow(true)

		// }
		else {
			// clearMarkers()
			setShowSelectAction(true);
		}
	};

	const updatePreviewDataShow = () => {
		previewDataShowtemp = !previewDataShow;
		setPreviewDataShow(!previewDataShow);
	};

	const onEventCallMarkerComplete = (position) => {
		if (activeInfoWindow) {
			activeInfoWindow.close();
		}
		setClickedMarkerPosition(position);
		setSearchParameterShow(true);
	};

	//Map Portion
	const initialCenter = {
		lat: 41.2851111,
		lng: -73.9189,
	};

	const initialZoom = 18;

	// Map Api Load Started
	const handleApiLoaded = (google) => {
		// addCenterControl(google);

		getCurrentLocation(google);
		addCurrentLocationControl(google);
		addDrawingTools(google);
		addSearchFunctionality(google);

		map = google.map;

		const clearMarkers = () => {
			if (markerCluster) {
				markerCluster.setMap(null);
			}
			setMapOnAll(null);
		};

		const deleteMarkers = () => {
			clearMarkers();
			loadedMarkers = [];
			if (markerCluster) {
				markerCluster.setMap(null);
			}
		};

		IndexOld.clearSelection = () => {
			deleteMarkers();
			if (selectedShape) {
				infoWindow.close();
				selectedShape.setMap(null);
			}
		};

		const setMapOnAll = (map) => {
			for (var i = 0; i < loadedMarkers.length; i++) {
				loadedMarkers[i].setMap(map);
			}
		};

		IndexOld.loadAllContactsFunction = async (storedContactPositionArray, hasAcess = undefined) => {

			if (access || (hasAcess !== undefined && hasAcess)) {
				showLoaderInfowindow("Load Contacts In Progress");
				if (storedContactPositionArray == null) {
					props.fetchAllContactsPositionArray({}, (response) => {
						if (response.success) {
							loadAllContactsInMap(response, hasAcess);
						} else {
							window.showNotification("ERROR", " No Contacts Found");
						}
					});
				} else {
					loadAllContactsInMap(storedContactPositionArray, hasAcess);
				}
			} else {
				noAccessNotification()
			}

		};

		const loadAllContactsInMap = async (response, hasAcess) => {
			setAllContactPositionArray(response);

			if (
				response.contacts_place_id.length === 0 &&
				response.contacts_lat_lng.length === 0
			) {
				window.showNotification("ERROR", " No Contacts Found");
			} else {
				drawingManager?.setDrawingMode(null);
				IndexOld.clearSelection();

				// deleteMarkers()
				// map.setZoom(1);
				IndexOld.smoothZoomInMap(map, 1, map.getZoom());
				if (activeInfoWindow) {
					activeInfoWindow.close();
				}

				let disposition;
				let markerIcon;
				// map.setZoom(1);
				let locations = response.latLngArray;
				var markers = locations.map(function (location, i) {
					// return new google.maps.Marker({
					//     position: location,
					//     label: labels[i % labels.length]
					// });
					disposition =
						location.dispositions !== undefined
							? location.dispositions
							: null;
					if (disposition != null) {
						markerIcon =
							process.env.REACT_APP_BASE_URL + disposition;
					} else {
						markerIcon =
							process.env.REACT_APP_APP_URL +
							"/assets/img/disposition/pin-marker-new.png";
					}
					var marker = new google.maps.Marker({
						position: location.locations,
						// label: labels[i % labels.length],
						map: map,
						animation: google.maps.Animation.DROP,
						icon: new google.maps.MarkerImage(markerIcon),
						// clickable: true,
						zIndex: 2000000001,
					});

					google.maps.event.addListener(marker, "click", function () {

						if (activeInfoWindow) {
							activeInfoWindow.close();
						}
						infoWindow = new google.maps.InfoWindow();

						infoWindow.setContent(
							pinningImageContent("Loading Contact Info")
						);
						infoWindow.setPosition(marker.getPosition());

						infoWindow.open(map);
						activeInfoWindow = infoWindow;
						getContactInfoByLatlng(location.locations, hasAcess);

					});
					// google.maps.event.addListener(marker, 'mouseover', function () {
					//     contactInfoLat = location.locations.lat;
					//     contactInfoLng = location.locations.lng;

					//     getContactInfoByLatlng(location.locations)
					// });
					// loadedMarkers.push(marker);
					return marker;
				});
				// Add a marker clusterer to manage the markers.
				markerCluster = new MarkerClusterer(map, markers, {
					imagePath:
						"https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
					maxZoom: 18,
					zoomOnClick: true,
				});
			}
		};

		// the smooth zoom function
		IndexOld.smoothZoomInMap = (map, max, cnt) => {
			if (cnt > max) {
				let z = google.maps.event.addListener(
					map,
					"zoom_changed",
					function (event) {
						google.maps.event.removeListener(z);
						IndexOld.smoothZoomInMap(map, max, cnt - 1);
					}
				);
				setTimeout(function () {
					map.setZoom(cnt);
				}, 80); // 80ms is what I found to work well on my system -- it might not work well on all systems
			} else if (cnt === max) {
				return;
			} else {
				let z = google.maps.event.addListener(
					map,
					"zoom_changed",
					function (event) {
						google.maps.event.removeListener(z);
						IndexOld.smoothZoomInMap(map, max, cnt + 1);
					}
				);
				setTimeout(function () {
					map.setZoom(cnt);
				}, 80); // 80ms is what I found to work well on my system -- it might not work well on all systems
			}
		};

		const getContactInfoByLatlng = async (locations, hasAcess = undefined) => {

			if (access || (hasAcess !== undefined && hasAcess)) {
				let contactName = "No Name provided";
				let contactEmail = "No email available";
				let contactNumber = "No number available";
				let savedContactId;
				try {
					await fetchContactInfoByLatlng({
						lat: locations.lat,
						long: locations.lng,
					}).then((response) => {
						if (response.data.status === "success") {
							let loadedMapContactDetails = response.data.data[0];
							savedContactId = loadedMapContactDetails.id;
							if (
								!Helper.isEmpty(
									loadedMapContactDetails.full_name
								) &&
								loadedMapContactDetails.full_name.trim() !== ""
							) {
								contactName = loadedMapContactDetails.full_name;
							}
							if (
								!Helper.isEmpty(loadedMapContactDetails.email) &&
								loadedMapContactDetails.email.trim() !== ""
							) {
								contactEmail = loadedMapContactDetails.email;
							}
							if (
								!Helper.isEmpty(loadedMapContactDetails.number) &&
								loadedMapContactDetails.number.trim() !== ""
							) {
								contactNumber = loadedMapContactDetails.number;
							}
							let content = ReactDOMServer.renderToString(
								<div>
									<table class="table table-bordered m-table m-table--border-success">
										<thead>
											<tr>
												<td>Name</td>
												<td>{contactName}</td>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td> Mobile </td>
												<td>{contactNumber}</td>
											</tr>
											<tr>
												<td> Email </td>
												<td>{contactEmail}</td>
											</tr>
											<tr>
												<td>
													{" "}
													<div className="infowindow-more-info-For-saved-Contact">
														{" "}
														More Info
													</div>{" "}
												</td>{" "}
												<td>
													<div className="infowindow-findNeighbour">
														{" "}
														Find Neighbour
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							);
							infoWindow.setContent(content);
						}
					});
				} catch (error) {
					console.log(error);
					IndexOld.clearSelection();
					window.showNotification("ERROR", "Something went wrong");
				}

				document
					.querySelector(".infowindow-more-info-For-saved-Contact")
					.addEventListener("click", function () {
						if (!Helper.isEmpty(savedContactId)) {
							// window.showSavedContactDetailsModal(savedContactId);
							window.handleGlobalContactDetailsModal({
								open: true,
								from: 'map',
								id: savedContactId
							})

						} else {
							window.showNotification("ERROR", "Data not available");
						}
					});

				document
					.querySelector(".infowindow-findNeighbour")
					.addEventListener("click", function () {
						onEventCallMarkerComplete(locations);
					});
			} else {
				noAccessNotification()
			}

		};

		window.searchByTagInMapModule = async (selectedTags, type) => {

			if (window.mapAccessCheck()) {
				if (type === LOAD_TAGS) {
					setLoadTagList(selectedTags);

					if (selectedTags.length === 0) {
						setLoadTagList([]);
						// setTagListShow(false)
					} else {
						setTagListShow(false);

						await loadContactsByTag({
							selectedTags: selectedTags,
						}).then((response) => {
							let responseData = response.data;
							setTagListShow(false);
							if (responseData.success) {
								drawingManager?.setDrawingMode(null);
								IndexOld.clearSelection();

								// deleteMarkers()
								// map.setZoom(1);
								IndexOld.smoothZoomInMap(map, 1, map.getZoom());
								if (activeInfoWindow) {
									activeInfoWindow.close();
								}

								let disposition;
								let markerIcon;
								// map.setZoom(1);
								let locations = responseData.latLngArray;
								var markers = locations.map(function (location, i) {
									// return new google.maps.Marker({
									//     position: location,
									//     label: labels[i % labels.length]
									// });
									disposition =
										location.dispositions !== undefined
											? location.dispositions
											: null;
									if (disposition != null) {
										markerIcon =
											process.env.REACT_APP_BASE_URL +
											disposition;
									} else {
										markerIcon =
											process.env.REACT_APP_APP_URL +
											"/assets/img/disposition/pin-marker-new.png";
									}

									var marker = new google.maps.Marker({
										position: location.locations,
										// label: labels[i % labels.length],
										map: map,
										animation: google.maps.Animation.DROP,
										icon: new google.maps.MarkerImage(
											markerIcon
										),
										// clickable: true,
										zIndex: 2000000001,
									});
									google.maps.event.addListener(
										marker,
										"click",
										function () {
											if (map.getZoom() < 18) {
												map.setCenter(marker.getPosition());
												// map.setZoom(18);
												IndexOld.smoothZoomInMap(
													map,
													18,
													map.getZoom()
												);
											}
											infoWindow =
												new google.maps.InfoWindow();

											infoWindow.setContent(
												pinningImageContent(
													"Loading Contact Info"
												)
											);
											infoWindow.setPosition(map.getCenter());

											infoWindow.open(map);
											activeInfoWindow = infoWindow;
											getContactInfoByLatlng(
												location.locations, true
											);

											// try{

											//     fetchContactInfoByLatlng({ lat: locations.lat, long:locations.lng }).then(response => {

											//         if(response.data.status === 'success'){
											//             let loadedMapContactDetails =response.data.data[0]
											//             console.log(loadedMapContactDetails)
											//         }
											//      else{
											//             onEventCallMarkerComplete(location.locations)
											//      }
											//         })
											// }
											//     catch(error){
											//         console.log(error)
											//         window.showNotification('ERROR',"Something went wrong");
											//         return null
											//     }
										}
									);
									loadedMarkers.push(marker);
									return marker;
								});
								// Add a marker clusterer to manage the markers.
								markerCluster = new MarkerClusterer(map, markers, {
									imagePath:
										"https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
									maxZoom: 18,
									zoomOnClick: true,
								});
							}
						});
					}
				} else if (type === EXCLUDE_TAGS) {
					setExcludeTags(selectedTags);
					setExcludeTagListShow(false);
				}
			} else {
				noAccessNotification()
			}

		};

		const showLoaderInfowindow = (message) => {
			infoWindow = new google.maps.InfoWindow();

			infoWindow.setContent(pinningImageContent(message));
			infoWindow.setPosition(map.getCenter());

			infoWindow.open(map);
			activeInfoWindow = infoWindow;
		};

		IndexOld.SearchMapByDisposition = async (selectedDispositions, dispositionList) => {

			if (window.mapAccessCheck()) {
				showLoaderInfowindow("Show Disposition in Progress");
				setLoadAllContacts(false);
				setExcludeTags([]);
				setLoadTagList([]);
				await fetchContactsWithSpecificDisposition({
					dispositionId: selectedDispositions,
				}).then((response) => {

					let responseData = response.data;
					// setTagListShow(false)
					if (responseData.success) {
						searchByDispositionLoopAndShow(responseData, selectedDispositions, dispositionList)
						//do something special
					} else {
						if (
							responseData.html
						) {
							window.showNotification("ERROR", responseData.html);
						} else {
							window.showNotification(
								"ERROR",
								"something went wrong"
							);
						}
					}
				});
			} else {
				noAccessNotification()
			}

		};

		function searchByDispositionLoopAndShow(responseData, selectedDispositions, dispositionList, reset = false) {

			let disposition;
			let markerIcon;

			if (!reset) {
				drawingManager?.setDrawingMode(null);

				IndexOld.smoothZoomInMap(map, 1, map.getZoom());

				if (activeInfoWindow) {
					activeInfoWindow.close();
				}
			}

			// Create an array of alphabetical characters used to label the markers.
			// var labels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
			let locations = responseData.latLngArray;
			var markers = locations.map(function (location, index, locations) {
				// return new google.maps.Marker({
				//     position: location,
				//     label: labels[i % labels.length]
				// });

				disposition =
					location.dispositions !== undefined
						? location.dispositions
						: null;
				if (disposition != null && disposition != "") {
					markerIcon =
						process.env.REACT_APP_APP_URL + disposition;
				} else {
					markerIcon =
						process.env.REACT_APP_APP_URL +
						"/assets/img/disposition/pin-marker-new.png";
				}

				var marker = new google.maps.Marker({
					position: location.locations,
					// label: labels[i % labels.length],

					animation: google.maps.Animation.DROP,
					icon: new google.maps.MarkerImage(markerIcon),
					// clickable: true,
					zIndex: 2000000001,
				});

				google.maps.event.addListener(marker, "mouseover", function () {

					let address = location.contactInfo.address ? location.contactInfo.address + ' ' : '';
					let city = location.contactInfo.city ? ',' + location.contactInfo.city + ' ' : '';
					let state = location.contactInfo.state ? ',' + location.contactInfo.state + ' ' : '';
					let fullAddress = address + city + state;
					let content = ReactDOMServer.renderToString(
						<div>
							<table class="table table-bordered m-table m-table--border-success search-dispositon-infobox">
								<thead>
									<tr>
										<td>Name</td>
										<td>{location.contactInfo.fullName}</td>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td> Address </td>
										<td>{fullAddress}</td>
									</tr>
									<tr>
										<td> Mobile </td>
										<td>{location.contactInfo.number}</td>
									</tr>
									<tr>
										<td> Email </td>
										<td>{location.contactInfo.email}</td>
									</tr>

									<tr>
										<td className="change-disposition-box"> Change Disposition </td>
										{dispositionDropdownForSearchDisp(location.contactInfo)}
									</tr>
									<tr>
										<td>
											{" "}
											<div className="infowindow-more-info-For-saved-Contact">
												{" "}
												More Info
											</div>{" "}
										</td>{" "}
										<td>
											<div className="infowindow-findNeighbour">
												{" "}
												Find Neighbour
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					);
					infoWindow.setContent(content);
					infoWindow.open(map, this);
					activeInfoWindow = infoWindow;

					document
						.querySelector(".infowindow-more-info-For-saved-Contact")
						.addEventListener("click", function () {
							if (
								location.contactInfo &&
								location.contactInfo.id &&
								location.contactInfo.id !== ""
							) {
								// For saved Contact
								window.handleGlobalContactDetailsModal({
									open: true,
									from: 'map',
									id: location.contactInfo.id
								})
							}
						});

					document.querySelector(".infowindow-findNeighbour").addEventListener("click", function () {
						let position = {
							lat: marker.getPosition().lat(),
							lng: marker.getPosition().lng(),
						};
						onEventCallMarkerComplete(position);
					})

					function dispositionDropdownForSearchDisp(value) {
						let dispId = value.disposition_id ?? '';
						return <td><FormControl>
							<Select
								native
								value={dispId}
								id="change_disposition_dropdown_search"
								style={{ width: '155px', fontSize: '14px', fontWeight: 400, paddingTop: '3px!important' }}>
								<option aria-label="" value="" style={{ color: 'red' }}>{dispId === '' ? 'no disposition' : 'remove disposition'}</option>

								{dispositionList && Array.isArray(dispositionList) && dispositionList.map((disp, index) => (
									<option key={index} value={disp.id} name={disp.icon_link}>{Utils.limitText(disp.name, 16)}</option>
								))
								}

							</Select>
						</FormControl></td>
					}

					if (document.querySelector(".search-dispositon-infobox #change_disposition_dropdown_search")) {
						document
							.querySelector(".search-dispositon-infobox #change_disposition_dropdown_search")
							.addEventListener("change", function () {


								let selectedValue = document
									.querySelector(".search-dispositon-infobox #change_disposition_dropdown_search").value


								changeContactDisposition({ contactId: location.contactInfo.id, dispositionId: selectedValue == '' ? null : selectedValue })
									.then((response) => {

										if (response.data.success) {

											if (selectedDispositions.includes(parseInt(selectedValue))) {
												let tmpLocation = [...locations];
												tmpLocation[index]['contactInfo']['disposition_id'] = selectedValue;
												for (let i = 0; i < dispositionList.length; i++) {
													console.log(dispositionList[i].id)
													console.log(selectedValue)
													if (dispositionList[i].id == selectedValue) {
														console.log(tmpLocation)
														if (tmpLocation[index]) {
															tmpLocation[index].dispositions = dispositionList[i].icon_link;

														}
														break;

														// console.log(locations[index])
														// locations[index].dispositions = dispositionList[i].icon_link;

													}
												}
												clearMarkers()
												responseData['latLngArray'] = tmpLocation
												searchByDispositionLoopAndShow(responseData, selectedDispositions, dispositionList, true)

											} else {
												clearMarkers()
												locations.splice(index, 1);
												responseData['latLngArray'] = locations
												searchByDispositionLoopAndShow(responseData, selectedDispositions, dispositionList, true)
											}
											// marker.setMap(null);
										}

									})
							})
					}
				});
				loadedMarkers.push(marker);
				return marker;
			});
			showMarkerCluster(markers)
		}

		function showMarkerCluster(markers) {
			// Add a marker clusterer to manage the markers.
			markerCluster = new MarkerClusterer(map, markers, {
				imagePath:
					"https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
				maxZoom: 18,
				zoomOnClick: true,
			});
		}

		window.showMarkerByLatitudeAndLongitudeFromDrawingDispositionContact = (
			map,
			latitude,
			longitude,
			geocoder,
			infowindow,
			name,
			prevMobileNumber,
			prevEmailAddress,
			address,
			city,
			state,
			zipCode,
			icon_link,
			value
		) => {
			let icon_link_for_disposition;
			let emailIcon;
			let mobileIcon;
			map = google.map;
			let location = {
				lat: parseFloat(latitude),
				lng: parseFloat(longitude),
			};
			// map.setZoom(18);
			IndexOld.smoothZoomInMap(map, 18, map.getZoom());

			// if (prevMobileNumber == '' || prevMobileNumber == null) {
			//     mobileIcon = "";
			// } else {
			//     mobileIcon = mobileSuccessHTML;
			// }

			// if (prevEmailAddress === '' || prevEmailAddress == null) {
			//     emailIcon = "";
			// }
			// else {
			//     emailIcon = emailSuccessHTML;
			// }
			let fullAddress = address + "," + city;
			if (icon_link != null) {
				if (icon_link !== "") {
					icon_link_for_disposition =
						process.env.REACT_APP_APP_URL + icon_link;
				} else {
					icon_link_for_disposition =
						process.env.REACT_APP_APP_URL +
						"/assets/img/disposition/plus-icon.png";
				}
			} else {
				icon_link_for_disposition =
					process.env.REACT_APP_APP_URL +
					"/assets/img/disposition/plus-icon.png";
			}
			addMarkerForDisposition(
				latitude,
				longitude,
				icon_link_for_disposition,
				map
			);

			// customBlockUI('.map-m-content').unBlock();
			// return true;
		};

		const addMarkerForDisposition = (latitude, longitude, icon, map) => {
			for (var i = 0; i < dispositionMarkers.length; i++) {
				if (
					dispositionMarkers[i].getPosition().lat() ==
					parseFloat(latitude).toFixed(5) &&
					dispositionMarkers[i].getPosition().lng() ==
					parseFloat(longitude).toFixed(5)
				) {
					dispositionMarkers[i].setIcon(icon);
				}
			}
		};
	};

	//Map Api Load ended

	const searchingParameter = (neighbourCount, maximumDistance) => {

		let focusPoint = clickedMarkerPosition;
		console.log(focusPoint);
		infoWindow = new google.maps.InfoWindow();
		// console.log(focusPoint)
		let jsonLocations = [];
		// var center = JSON.stringify(focusPoint).replace("(",'');
		// var center = focusPoint.replace(")",'');
		// center = center.replace("(",'');
		// center = center.split(', ');
		//if circle only radius & center send.
		jsonLocations =
			'{"Latitude":"' +
			clickedMarkerPosition.lat +
			'","Longitude":"' +
			clickedMarkerPosition.lng +
			'","MaxCount":' +
			neighbourCount +
			',"MaxDistance":"' +
			maximumDistance / 1000 +
			'"}';
		infoWindow.setContent(
			"<b>Searching</b><br/>" +
			// '<span class="map-loader">' +
			'<img src="' +
			process.env.REACT_APP_APP_URL +
			'/assets/img/disposition/processing.gif" width="160" class="img-thumbnail"/>' +
			// '</span>' +
			""
		);
		// infoWindow.setPosition(newShape.getCenter());
		// infoWindow.maxWidth(200);
		infoWindow.open(map);
		activeInfoWindow = infoWindow;
		submitToGeoColeCircle(jsonLocations, focusPoint);
	};

	const addSearchFunctionality = (google) => {
		map = google.map;
		autocomplete = new google.maps.places.Autocomplete(
			/** @type {!HTMLInputElement} */(
				document.getElementById("address")
			),
			{
				types: ["geocode", "establishment"],
				componentRestrictions: countryRestrict,
				fields: [
					"formatted_address",
					"name",
					"place_id",
					"url",
					"geometry",
				],
			}
		);
		places = new google.maps.places.PlacesService(map);
		geocoder = new google.maps.Geocoder();

		if (document.getElementById("address_search_button")) {
			try {
				document
					.getElementById("address_search_button")
					.addEventListener("click", function () {
						geocodeAddress(geocoder, map);
					});
			} catch (e) {
				console.log('map unmount')
			}

		}


		autocomplete.addListener("place_changed", () => {
			var place = autocomplete.getPlace();
			if (place.geometry) {
				geocodeAddress(geocoder, map);
				// map.panTo(place.geometry.location);
				// map.setZoom(15);
				// search();
			} else {
				document.getElementById("autocomplete").placeholder =
					"Enter an address";
			}
		});

		function geocodeAddress(geocoder, resultsMap) {
			var infowindow = new google.maps.InfoWindow();
			var address = document.getElementById("address").value;
			geocoder.geocode({ address: address }, function (results, status) {
				if (status === "OK") {
					resultsMap.setCenter(results[0].geometry.location);
					var marker = new google.maps.Marker({
						map: resultsMap,
						position: results[0].geometry.location,
					});
					google.maps.event.addListener(marker, "click", function () {
						infowindow.setContent(
							"<div><strong>Congratulation!!</strong><br>" +
							"You successfully searched for<br>" +
							address +
							"</div>"
						);
						infowindow.open(map, this);
					});
					loadedMarkers.push(marker);
					return marker;
				} else {
					if (status === "ZERO_RESULTS") {
						window.showNotification(
							"ERROR",
							"No Address Found!! Please Try Again"
						);
					} else {
						window.showNotification(
							"ERROR",
							"No address to search"
						);
					}
				}
			});
		}
	};

	const searchAddressFrom = () => { };

	const getCurrentLocation = (google) => {
		map = google.map;

		// Try HTML5 geolocation.
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const pos = {
						lat: position.coords.latitude,
						lng: position.coords.longitude,
					};
					map.setCenter(pos);
				},
				() => {
					handleLocationError(true, map.getCenter());
				}
			);
		} else {
			// Browser doesn't support Geolocation
			handleLocationError(false, map.getCenter());
		}
	};

	const handleLocationError = (browserHasGeolocation, pos) => {
		// console.log(

		if (access) {
			window.showNotification('INFO',
				browserHasGeolocation
					? "Location service disabled in your browser"
					: "Your browser doesn't support Location."
			);
		}

	};

	const addCurrentLocationControl = (google) => {
		map = google.map;
		const currentControlDiv = document.createElement("div");
		currentLocationControl(currentControlDiv, google);
		map.controls[google.maps.ControlPosition.TOP_CENTER].push(
			currentControlDiv
		);
	};

	const currentLocationControl = (controlDiv, google) => {
		// Set CSS for the control border.
		const controlUI = document.createElement("div");
		controlUI.style.backgroundColor = "#fff";
		controlUI.style.border = "2px solid #fff";
		controlUI.style.borderRadius = "3px";
		controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
		controlUI.style.cursor = "pointer";
		controlUI.style.marginBottom = "22px";
		controlUI.style.textAlign = "center";
		controlUI.title = "Click to locate current location";
		controlDiv.appendChild(controlUI);
		// Set CSS for the control interior.
		const controlText = document.createElement("div");
		controlText.style.color = "rgb(25,25,25)";
		controlText.style.fontFamily = "Roboto,Arial,sans-serif";
		controlText.style.fontSize = "16px";
		controlText.style.lineHeight = "38px";
		controlText.style.paddingLeft = "5px";
		controlText.style.paddingRight = "5px";
		controlText.innerHTML = "Current Location";
		controlUI.appendChild(controlText);
		// Setup the click event listeners: simply set the map to Chicago.
		controlUI.addEventListener("click", () => {
			getCurrentLocation(google);
		});
	};

	const addDrawingTools = (google) => {
		map = google.map;

		let options = {
			strokeWeight: 2,
			strokeColor: "#c5edec",
			strokeOpacity: 0.8,
			fillOpacity: 0.45,
			editable: true,
			draggable: true,
		};

		drawingManager = new google.maps.drawing.DrawingManager({
			drawingMode: google.maps.drawing.OverlayType.MARKER,
			drawingControl: true,
			drawingControlOptions: {
				position: google.maps.ControlPosition.TOP_CENTER,
				drawingModes: ["circle", "rectangle", "polygon", "marker"],
			},
			markerOptions: {
				animation: google.maps.Animation.DROP,
				icon:
					process.env.REACT_APP_APP_URL +
					"/assets/img/disposition/pin-marker-new.png",
				draggable: true,
				clickable: true,
				title: "Neighbour Search",
				zIndex: 2000000001,
				crossOnDrag: true,
			},
			rectangleOptions: options,
			circleOptions: options,
			polygonOptions: options,
			map: map,
		});
		if (drawingManager) {
			drawingManager.setMap(map);
		}

		google.maps.event.addListener(
			drawingManager,
			"overlaycomplete",
			function (e) {
				if (activeInfoWindow) {
					activeInfoWindow.close();
				}

				if (e.type !== google.maps.drawing.OverlayType.MARKER) {
					if (drawingManager) {
						drawingManager.setDrawingMode(null);
					}
					let newShape = e.overlay;
					newShape.type = e.type;
					selectedShape = newShape;

					infoWindow = new google.maps.InfoWindow();
					if (newShape.type === "polygon") {
						//When a polygon is drawn
						google.maps.Polygon.prototype.my_getBounds =
							function () {
								let bounds = new google.maps.LatLngBounds();
								this.getPath().forEach(function (
									element,
									index
								) {
									bounds.extend(element);
								});
								return bounds;
							};

						let timer;
						google.maps.event.addListener(
							newShape.getPath(),
							"set_at",
							function () {
								clearTimeout(timer);
								timer = setTimeout(function () {
									onEventCall(newShape);
								}, 500);
							}
						);

						google.maps.event.addListener(
							newShape.getPath(),
							"insert_at",
							function () {
								clearTimeout(timer);
								timer = setTimeout(function () {
									onEventCall(newShape);
								}, 500);
							}
						);

						onEventCall();
						function onEventCall() {
							let vertices = newShape.getPath();
							let polygonArea =
								google.maps.geometry.spherical.computeArea(
									vertices
								) / 1000;
							if (polygonArea > 700) {
								window.showNotification(
									"ERROR",
									"Too big area to search!"
								);
								return false;
							} else {
								let jsonLocations = [];
								for (
									let i = vertices.getLength() - 1;
									i >= 0;
									i--
								) {
									let xy = vertices.getAt(i);
									jsonLocations.push({
										Latitude: xy.lat(),
										Longitude: xy.lng(),
									});
								}
								jsonLocations = JSON.stringify(jsonLocations);
								submitToGeoColePolygon(
									"polygon",
									jsonLocations,
									newShape.my_getBounds().getCenter(),
									map
								);
								infoWindow.setContent(
									window.mapAccessCheck() ? "<b>Searching</b><br/>" +
										// '<span class="map-loader">' +
										'<img src="' +
										process.env.REACT_APP_APP_URL +
										'/assets/img/disposition/driving-map.gif" width="160" class="img-thumbnail"/>' +
										// '</span>' +
										"" :
										'<img src="' +
										process.env.REACT_APP_APP_URL +
										'/assets/img/disposition/error-map-update.gif" width="160" class="img-thumbnail"/>' +
										// '</span>' +
										"<br /><b>" + (isPaused ? "Access has been disabled" : "No geofarming subscription") + "</b>"

								);
								infoWindow.setPosition(
									newShape.my_getBounds().getCenter()
								);
								infoWindow.open(map);
								activeInfoWindow = infoWindow;
							}
						}
					} else if (newShape.type === "circle") {
						//If a circle is drawn
						google.maps.event.addListener(
							newShape,
							"dragend",
							onEventCall
						);
						google.maps.event.addListener(
							newShape,
							"radius_changed",
							onEventCall
						);
						onEventCall();
						function onEventCall() {
							let jsonLocations = [];
							//Circle radius in kilometer
							let newRadius = newShape.getRadius() / 1000;
							if (newRadius > 2) {
								window.showNotification(
									"ERROR",
									"Too big area to search!"
								);
								return false;
							} else {
								//Center of the circle
								var center = JSON.stringify(
									newShape.getCenter()
								).replace('{"lat":', "");
								center = center.replace('"lng":', "");
								center = center.replace("}", "");
								center = center.split(",");

								//if circle only radius & center send.
								jsonLocations =
									'{"Latitude":"' +
									center[0] +
									'","Longitude":"' +
									center[1] +
									'","MaxCount":5000,"MaxDistance":"' +
									newRadius +
									'"}';
								infoWindow.setContent(
									window.mapAccessCheck() ? "<b>Searching</b><br/>" +
										// '<span class="map-loader">' +
										'<img src="' +
										process.env.REACT_APP_APP_URL +
										'/assets/img/disposition/driving-map.gif" width="160" class="img-thumbnail"/>' +
										// '</span>' +
										""
										:
										'<img src="' +
										process.env.REACT_APP_APP_URL +
										'/assets/img/disposition/error-map-update.gif" width="160" class="img-thumbnail"/>' +
										// '</span>' +
										"<br /><b>" + (isPaused ? "Access has been disabled" : "No geofarming subscription") + "</b>"
								);
								infoWindow.setPosition(newShape.getCenter());
								// infoWindow.maxWidth(200);
								infoWindow.open(map);
								activeInfoWindow = infoWindow;
								submitToGeoColeCircle(
									jsonLocations,
									newShape.getCenter(),
									map
								);
							}
						}
					} else if (newShape.type === "rectangle") {
						//if rectangle is drawn
						let timer;
						google.maps.event.addListener(
							newShape,
							"bounds_changed",
							function () {
								clearTimeout(timer);
								timer = setTimeout(function () {
									onEventCall(newShape);
								}, 500);
							}
						);

						onEventCall(newShape);
						function onEventCall(shape) {
							let northEast = shape.bounds.getNorthEast();
							let southWest = shape.bounds.getSouthWest();
							let distanceBetweenPoints =
								getDistanceFromLatLonInKm(
									northEast.lat(),
									northEast.lng(),
									southWest.lat(),
									southWest.lng()
								);
							if (distanceBetweenPoints > 2) {
								window.showNotification(
									"ERROR",
									"Too big area to search!"
								);
								return false;
							} else {
								let jsonLocations = JSON.stringify([
									{
										Latitude: northEast.lat(),
										Longitude: northEast.lng(),
									},
									{
										Latitude: northEast.lat(),
										Longitude: southWest.lng(),
									},
									{
										Latitude: southWest.lat(),
										Longitude: southWest.lng(),
									},
									{
										Latitude: southWest.lat(),
										Longitude: northEast.lng(),
									},
									{
										Latitude: northEast.lat(),
										Longitude: northEast.lng(),
									},
								]);

								submitToGeoColePolygon(
									"rectangle",
									jsonLocations,
									shape.bounds.getCenter(),
									map
								);
								infoWindow.setContent(
									window.mapAccessCheck() ? "<b>Searching</b><br/>" +
										// '<span class="map-loader">' +
										'<img src="' +
										process.env.REACT_APP_APP_URL +
										'/assets/img/disposition/driving-map.gif" width="160" class="img-thumbnail"/>' +
										// '</span>' +
										"" :
										'<img src="' +
										process.env.REACT_APP_APP_URL +
										'/assets/img/disposition/error-map-update.gif" width="160" class="img-thumbnail"/>' +
										// '</span>' +
										"<br /><b>" + (isPaused ? "Access has been disabled" : "No geofarming subscription") + "</b>"
								);
								infoWindow.setPosition(
									shape.bounds.getCenter()
								);
								infoWindow.open(map);
								activeInfoWindow = infoWindow;
							}
						}
					}
				} else if (e.type == google.maps.drawing.OverlayType.MARKER) {

					if (drawingManager) {
						drawingManager.setDrawingMode(null);
					}
					IndexOld.clearSelection();
					// drawingManager.setDrawingMode(null);
					// drawingManager.setDrawingMode(null);
					let timer;
					let newShape = e.overlay;
					newShape.type = e.type;
					selectedShape = newShape;
					newShape.setDraggable(true);
					infoWindow = new google.maps.InfoWindow();

					//notification Count
					google.maps.event.addListener(
						newShape,
						"mouseover",
						function () {
							if (notificationCount === 0) {
								window.showNotification(
									"SUCCESS",
									"You can move this marker or click to find neighbours"
								);
								notificationCount++;
							}
						}
					);

					google.maps.event.addListener(
						newShape,
						"dragend",
						function () {
							clearTimeout(timer);
							timer = setTimeout(function () {
								onEventCall(newShape);
							}, 500);
						}
					);
					google.maps.event.addListener(
						newShape,
						"click",
						function () {
							clearTimeout(timer);
							timer = setTimeout(function () {
								onEventCall(newShape);
							}, 500);
						}
					);

					// setSelection(newShape);
					onEventCall(newShape);
					function onEventCall(newShape) {
						if (map.getZoom() < 18) {
							map.setCenter(newShape.getPosition());
							// map.setZoom(18);
							IndexOld.smoothZoomInMap(map, 18, map.getZoom());
						}
						let position = {
							lat: newShape.getPosition().lat(),
							lng: newShape.getPosition().lng(),
						};
						setClickedMarkerPosition(position);
						setSearchParameterShow(true);

						// let htmlForInputMarker = '<div class="neighbourSearchDiv">Select Action' +
						//     '<br/><br/><input class="form-control m-input search-neighbour-from-first-point d-none" type="text" value="'+newShape.getPosition()+'">' +
						//     '<button type="button" class="btn m-btn--pill btn-primary search-specific-house"' +
						//     'style="font-weight:400!important;">Find Contacts At This Location\n </button>' +
						//     '<hr class="mc__divider"></hr><br/>' +
						//     '<button type="button" class="btn m-btn--pill btn-primary button-to-search-neighbour"' +
						//     'style="font-weight:400!important;width:100%;background-color:#28a745!important">Neighbor Search </button><br/>' +
						//     '</div>';

						// infoWindow.setContent(htmlForInputMarker);
						// infoWindow.setPosition(newShape.getPosition());
						// infoWindow.open(map);
						// activeInfoWindow = infoWindow;
					}
				}
			}
		);

		google.maps.event.addListener(
			drawingManager,
			"drawingmode_changed",
			function () {
				IndexOld.clearSelection();
				let option;
				let mode;
				let drawingOption;
				if (drawingManager) {
					mode = drawingManager.getDrawingMode();
				}
				if (mode === "polygon") {
					option = ["polygonOptions", "#ff1e22"];
				} else if (mode === "circle") {
					option = ["circleOptions", "#FF1493"];
				} else if (mode === "rectangle") {
					option = ["rectangleOptions", "#32CD32"];
				} else {
					return;
				}
				if (drawingManager) {
					drawingOption = drawingManager.get(option[0]);
					drawingOption.fillColor = option[1];
					drawingManager.set(option[0], drawingOption);
				}

			}
		);

		// google.maps.event.addListener(map, 'click', clearSelection);
	};

	const submitToGeoColePolygon = (type, jsonData, position) => {
		submitToBackend(jsonData, position);
	};

	const submitToGeoColeCircle = (jsonData, infoWindowCenter) => {
		submitToBackend(jsonData, infoWindowCenter);
	};

	window.fetchMapDataWithDisposition = async () => {
		// infoWindow.setContent(loaderContent)
		infoWindow.setContent(pinningImageContent("Pin Locations in Progress"));
		infoWindow.open(map, this);

		await getMapDataWithDisposition({
			contactList: selectedContacts,
			excludeTags: excludeTags,
		}).then((response) => {
			if (response.data.success) {
				response.data.data.map((location, index) => {
					clearInfowAndShape();
					//to center map in first index, uncomment if user requirement
					showMarkerByLatitudeAndLongitudeFromDrawing(
						parseFloat(location["latitude"]),
						parseFloat(location["longitude"]),
						location["first_name"] + " " + location["last_name"],
						location["number"],
						location["email"],
						location["address"],
						location["city"],
						location["state"],
						location["zip"],
						location["icon_link"],
						location
					);
				});
			}
		});
	};

	const clearInfowAndShape = () => {
		if (selectedShape) {
			infoWindow.close();
			selectedShape.setMap(null);
		}
	};

	const showMarkerByLatitudeAndLongitudeFromDrawing = (
		latitude,
		longitude,
		name,
		prevMobileNumber,
		prevEmailAddress,
		address,
		city,
		state,
		zipCode,
		icon_link,
		value,
		infoWindowOpen = false,
		removeSelectedMarker = false
	) => {

		if (removeSelectedMarker) {

			for (let i = 0; i < dispositionMarkers.length; i++) {

				if (dispositionMarkers[i].getPosition().lat() == latitude
					&& dispositionMarkers[i].getPosition().lng() == longitude) {
					dispositionMarkers[i].setMap(null)
					break;
				}
			}
		}
		let icon_link_for_disposition;
		let viewEmail = prevEmailAddress;
		let viewNumber = prevMobileNumber;
		// map.setZoom(18);

		if (
			prevMobileNumber === undefined ||
			prevMobileNumber === "" ||
			prevMobileNumber == null
		) {
			viewNumber = "Not available";
			prevMobileNumber = "";
		}
		if (
			prevEmailAddress === undefined ||
			prevEmailAddress === "" ||
			prevEmailAddress == null
		) {
			viewEmail = "Not available";
			prevEmailAddress = "";
		}

		let fullAddress = address + "," + city;
		if (icon_link != null) {
			if (icon_link !== "") {
				icon_link_for_disposition =
					process.env.REACT_APP_APP_URL + icon_link;
			} else {
				icon_link_for_disposition =
					process.env.REACT_APP_APP_URL +
					"/assets/img/disposition/plus-icon.png";
			}
		} else {
			icon_link_for_disposition =
				process.env.REACT_APP_APP_URL +
				"/assets/img/disposition/plus-icon.png";
		}

		var marker = new google.maps.Marker({
			position: {
				lat: latitude,
				lng: longitude,
			},
			map: map,
			animation: google.maps.Animation.DROP,
			icon: new google.maps.MarkerImage(icon_link_for_disposition),
			visible: true,
			clickable: true,
			zIndex: 2000000001,
			id: value.id
		});

		dispositionMarkers.push(marker);
		loadedMarkers.push(marker);

		// marker.setMap(map);

		if (infoWindowOpen) {
			markerActions()
		}
		google.maps.event.addListener(marker, "mouseover", markerActions)

		function markerActions() {
			let content = ReactDOMServer.renderToString(
				<div>
					<table class="table table-bordered m-table m-table--border-success preview-data-infobox">
						<thead>
							<tr>
								<td>Name</td>
								<td>{name}</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Address</td>
								<td>{fullAddress}</td>
							</tr>
							<tr>
								<td> Mobile </td>
								<td>{viewNumber}</td>
							</tr>
							<tr>
								<td Onclick> Email </td>
								<td>{viewEmail}</td>
							</tr>
							{value && value.id && value.id !== '' &&
								<tr>
									<td className="change-disposition-box" style={{ width: '16px' }}> Change Disposition </td>
									{dispositionDropdown(value)}
								</tr>
							}
							<tr>
								<td colspan="2">
									{" "}
									<div className="infowindow-more-info-button">
										{" "}
										More Info
									</div>{" "}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			);
			infoWindow.setContent(content);
			infoWindow.open(map, this);
			activeInfoWindow = infoWindow;

			document
				.querySelector(".infowindow-more-info-button")
				.addEventListener("click", clickAction)


			if (document.querySelector(".preview-data-infobox #change_disposition_dropdown")) {
				document
					.querySelector(".preview-data-infobox #change_disposition_dropdown")
					.addEventListener("change", function () {


						let selectedValue = document
							.querySelector(".preview-data-infobox #change_disposition_dropdown").value


						changeContactDisposition({ contactId: value.id, dispositionId: selectedValue == '' ? null : selectedValue })
							.then((response) => {

								if (response.data.success) {
									value['disposition_id'] = selectedValue;
									if (selectedValue == '') {
										icon_link = ''
									}
									else {
										for (let i = 0; i < dispositionList.length; i++) {
											if (dispositionList[i].id == selectedValue) {
												icon_link = dispositionList[i].icon_link;
												break;
											}
										}
									}

									showMarkerByLatitudeAndLongitudeFromDrawing(
										latitude,
										longitude,
										name,
										prevMobileNumber,
										prevEmailAddress,
										address,
										city,
										state,
										zipCode,
										icon_link,
										value,
										false
									)
									marker.setMap(null)
									// contactAddSuccess({insertedData: value,icon_link:icon_link}, false)
								}

							})
					})
			}
		}
		function clickAction() {
			if (
				value &&
				value.id &&
				value.id !== ""
			) {
				// For saved Contact old
				// window.handleGlobalContactDetailsModal({
				// 	open: false,
				// 	from: 'map',
				// 	id: value.id
				// })
				// For saved Contact new


				window.handleGlobalContactDetailsForDealModal({
					open: true,
					from: 'map',
					id: value.id
				})
			} else {
				// Usaved Contact
				if (prevEmailAddress !== undefined && prevEmailAddress != null && prevEmailAddress !== '') {
					prevEmailAddress = prevEmailAddress.toLowerCase()
				}
				IndexOld.onEventCallMarkerCompleteByMap(
					name,
					prevMobileNumber,
					prevEmailAddress,
					address,
					city,
					state,
					zipCode,
					value
				);
			}
		}
		// To Close infowindow after mouse out
		// google.maps.event.addListener(marker, 'mouseout', function () {
		//     infoWindow.close();
		// });

		function dispositionDropdown(value) {

			let dispId = value.disposition_id ?? '';
			return <td><FormControl>
				<Select
					native
					value={dispId}
					id="change_disposition_dropdown"
					style={{ width: '155px', fontSize: '14px', fontWeight: 400, paddingTop: '3px!important' }}>
					<option aria-label="" value="" style={{ color: 'red' }}>{dispId === '' ? 'no disposition' : 'remove disposition'}</option>

					{dispositionList && Array.isArray(dispositionList) && dispositionList.map((disp, index) => (
						<option key={index} value={disp.id} name={disp.icon_link}>{Utils.limitText(disp.name, 16)}</option>
					))
					}

				</Select>
			</FormControl></td>
		}

		google.maps.event.addListener(marker, "click", clickAction)
		// if (map.getZoom() < 18) {
		// 	map.setCenter(marker.getPosition());
		// 	// map.setZoom(18);
		// 	window.smoothZoomInMap(map, 8, map.getZoom());
		// }
		// window.onEventCallMarkerCompleteByMap(
		// 	name,
		// 	prevMobileNumber,
		// 	prevEmailAddress,
		// 	address,
		// 	city,
		// 	state,
		// 	zipCode,
		// 	value
		// );
		// });

		IndexOld.onEventCallMarkerCompleteByMap = async (
			fullName,
			mobileNumber,
			emailAddress,
			address,
			city,
			state,
			zipCode,
			value
		) => {
			infoWindow.setContent(
				infoWindow.getContent() + loaderContentAppend
			);
			activeInfoWindow = infoWindow;
			let arrayName = fullName.split(" ");
			let allInfos;
			let jsonData;
			if (emailAddress == null) {
				emailAddress = "";
			}

			jsonData =
				'{"Address":"' +
				address +
				'","state":"' +
				state +
				'","zip":"' +
				zipCode +
				'"}';
			allInfos = {
				firstName: arrayName[0],
				first_name: arrayName[0],
				lastName: arrayName[1],
				last_name: arrayName[1],
				address: address,
				city: city,
				state: state,
				zip: zipCode,
				number: mobileNumber,
				email: emailAddress,
			};

			await getSingleContactFromMap({
				jsonData: jsonData,
				allInfos: allInfos,
				value: value,
			}).then((response) => {

				try {
					let responseData = response.data;

					if (responseData.success) {
						if (activeInfoWindow) {
							activeInfoWindow.close();
						}
						let markerContact = responseData.data.apiResponse;
						try {
							if (markerContact.EmailAddress !== undefined && markerContact.EmailAddress != null && markerContact.EmailAddress !== '') {
								markerContact.EmailAddress = markerContact.EmailAddress.toLowerCase()
							}
						}
						catch (e) {
							console.log(e)
						}

						if (markerContact) {
							markerContact.latitude = value.latitude;
							markerContact.longitude = value.longitude;
						}

						if (allInfos) {
							allInfos.latitude = value.latitude;
							allInfos.longitude = value.longitude;
						}

						setMarkerContactDetails(markerContact);
						setSavedContactDetails(allInfos);
						setCampaignList(responseData.data.campaigns);
						setDispositionList(responseData.data.userDispositions);
						setDispositionInfo(responseData.data.dispositionInfo);
						if (value.latitude && value.longitude) {
							let location = {
								latitude: value.latitude,
								longitude: value.longitude
							};
							setContactLocation(location);
						} else {
							setContactLocation(null);
						}

						setShowContactDetails(true);
					}
					else {
						window.showNotification('ERROR', 'Something went wrong, Please tyy again')
						infoWindow.setContent(
							infoWindow.getContent()
						);
						activeInfoWindow = infoWindow;
					}
				}
				catch (e) {
					console.log(e)
					window.showNotification('ERROR', 'Something went wrong, Please tyy again')
					infoWindow.setContent(
						infoWindow.getContent()
					);
					activeInfoWindow = infoWindow;
				}

			});
		};
	};

	window.nextWithMapContacts = () => {
		setShowSelectAction(true);
	};

	window.globalRouteLink = (path, state = null) => {
		if (state) {
			history.push({
				pathname: path,
				state: state
			});
		} else {
			history.push({
				pathname: path
			});
		}
	}

	const submitToBackend = (jsonData, position) => {

		if (window.mapAccessCheck()) {
			fetchMapFinderData({
				jsonData: jsonData,
			})
				.then((response) => {
					setSearchParameterShow(false);
					// console.log(response.data.data)
					let html =
						'<img src="' +
						process.env.REACT_APP_APP_URL +
						'/assets/img/disposition/error-map-update.gif" width="150" class="img-thumbnail"/><br>' +
						"<b>No new lead found in this area.</b><br>";
					if (response.data.success) {
						let result = response.data.data.html;

						setSelectedContacts(result);


						if (result.length && previewDataShowtemp) {
							html =
								'<img src="' +
								process.env.REACT_APP_APP_URL +
								'/assets/img/disposition/success-map.gif" width="150" class="img-thumbnail"/><br/><br/>' +
								"<b>We found " +
								result.length +
								" new leads for you!</b><br />" +
								'<a id="open-process-modal" class="btn btn-primary ' +
								"open-process-modal " +
								'mt-2 text-light" onclick="window.fetchMapDataWithDisposition()">Pin the Locations <i class="la la-angle-double-right"></i></a>';
						}
						if (result.length && !previewDataShowtemp) {
							html =
								'<img src="' +
								process.env.REACT_APP_APP_URL +
								'/assets/img/disposition/success-map.gif" width="150" class="img-thumbnail"/><br/><br/>' +
								"<b>We found " +
								result.length +
								" new leads for you!</b><br />" +
								'<a id="pin-find-locations-by-drawing" class="btn btn-primary ' +
								"open-process-modal " +
								'mt-2 text-light" onclick="window.nextWithMapContacts()">Next <i class="la la-angle-double-right"></i></a>';
						}

						infoWindow.setPosition(position);
						infoWindow.setContent(html);
						infoWindow.open(map);
					} else {

						if (response.data.message === 'search-limit-error') {

							if (Utils.getAccountData('agencyId') === 1) {
								html = '<img src="' +
									process.env.REACT_APP_APP_URL +
									'/assets/img/disposition/error-map-update.gif" width="150" class="img-thumbnail" style="margin-bottom: 10px;"/><br>' +
									"<b style='font-size:18px;font-weight:400!important;'>search limit exceed!!</b><br>" +
									'<button type="button" class="btn m-btn--pill btn-primary"' +
									"style='font-weight: 400!important;color: red;margin-top: 11px;' >Please contact support\n </button>"
							}
							else if (Utils.getAccountData('userIsOwner')) {
								html = '<img src="' +
									process.env.REACT_APP_APP_URL +
									'/assets/img/disposition/error-map-update.gif" width="150" class="img-thumbnail" style="margin-bottom: 10px;"/><br>' +
									"<b style='font-size:18px;font-weight:400!important;'>search limit exceed!!</b><br>" +
									'<button type="button" class="btn m-btn--pill btn-primary update-map-package"' +
									"style='font-weight: 400!important;color: red;margin-top: 11px;' >Please update package\n </button>"
							} else {
								html = '<img src="' +
									process.env.REACT_APP_APP_URL +
									'/assets/img/disposition/error-map-update.gif" width="150" class="img-thumbnail" style="margin-bottom: 10px;"/><br>' +
									"<b style='font-size:18px;font-weight:400!important;'>search limit exceed!!</b><br>" +
									'<button type="button" class="btn m-btn--pill btn-primary"' +
									"style='font-weight: 400!important;color: red;margin-top: 11px;' >Please update package\n </button>"
							}

						}
						infoWindow.setPosition(position);
						infoWindow.setContent(html);
						infoWindow.open(map);
						if (document.querySelector(".update-map-package")) {
							document.querySelector(".update-map-package")
								.addEventListener("click", function () {
									window.globalRouteLink('/map/integration');
								});
						}
					}
				})
				.catch((error) => {
					console.log(error);
					window.showNotification("ERROR", "Server Error");
				})
				.finally(() => { });
		} else {
			noAccessNotification()
		}

	};

	const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
		var R = 6371; // Radius of the earth in km
		var dLat = deg2rad(lat2 - lat1); // deg2rad below
		var dLon = deg2rad(lon2 - lon1);
		var a =
			Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(deg2rad(lat1)) *
			Math.cos(deg2rad(lat2)) *
			Math.sin(dLon / 2) *
			Math.sin(dLon / 2);
		var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		var d = R * c; // Distance in km
		return d;
	};

	const deg2rad = (deg) => {
		return deg * (Math.PI / 180);
	};

	const submitActionData = (selectedAction, processId, campaignId = null) => {
		setShowSelectAction(false);
		if (selectedAction === CREATELIST) {
			if (!Helper.isEmpty(processId)) {
				setMapLeadProcessId(processId);
				setShowOrderName(true);
			}
		} else if (selectedAction === SENDMESSAGE) {
			// setSendingProcess(true);
			setShowInstanceSend(true);
		} else if (selectedAction === ADDTOCAMPAIGN) {
			setCampaignId(campaignId);
			setShowDripMode(true);
		}
	};

	const submitOrderName = async (orderName) => {
		await mapOrderNameEdit({
			title: orderName,
			order_id: mapLeadProcessId,
		}).then((response) => {
			if (response.data.success) {
				Utils.showNotification("Order name saved", "SUCCESS");
				setShowOrderName(false);
				IndexOld.clearSelection();
			} else {
				Utils.showNotification("Unable to update the title", "ERROR");
			}
		});
	};

	const selectionSubmitProcess = (process) => {
		if (process == STARTFROMSCRATCH) {
			setShowInstanceSend(true);
			setSendingProcess(false);
		} else {
			// alert('Quick reply')
		}
	};

	/* for send module */
	const [showSmsSend, setShowSmsSend] = useState(false);
	const [instantSendType, setInstantSendType] = useState("sms");
	const handleInstantSendType = (type) => {
		setInstantSendType(type);
	};
	const instantSendNextStep = () => {
		setShowSmsSend(true);
	};
	const updateDispositionSearch = (event) => {
		if (showDispositionSelect === true) {
			setShowDispositionSelect(false);
		} else {
			setShowDispositionSelect(true);
		}
	};

	const updateSelectedMultipleValue = (event) => {
		// console.log(value)
		let value = event.target.value;

		if (selectedDispositionId.length > 0) {
			value = value[selectedDispositionId.length];
		} else {
			value = value[0];
		}

		let indexOf = selectedDispositionId.indexOf(value.id);

		if (indexOf < 0) {
			selectedDispositionId.push(value.id);
			selectedDispositionName.push(value.name);
		} else {
			selectedDispositionId.splice(indexOf, 1);
			selectedDispositionName.splice(indexOf, 1);
		}
		setUpdate(!update);

	};

	const contactAddSuccess = (savedContactInfo, updateDisposition = false) => {

		if (updateDisposition) {
			props.fetchUserDisposition()
		}
		setShowContactDetails(false);
		let insertedData = savedContactInfo.insertedData;
		insertedData["saved"] = "true";
		showMarkerByLatitudeAndLongitudeFromDrawing(
			parseFloat(insertedData.latitude),
			parseFloat(insertedData.longitude),
			insertedData.first_name + " " + insertedData.last_name,
			insertedData.number,
			insertedData.email,
			insertedData.address,
			insertedData.city,
			insertedData.state,
			insertedData.zip,
			savedContactInfo.icon_link,
			insertedData,
			false,
			true
		);
	};

	const handleOpenSendModal = () => {
		switch (instantSendType) {
			case "email":
				setOpenEmailModal(true);
				break;
			case "sms":
				setOpenSMSModal(true);
				break;
			case "voice":
				setOpenVoiceMailModal(true);
				break;
			case "video":
				setOpenVideoMailModal(true);
				break;
			case "postcard":
				setActiveMailItemType(POSTCARD)
				setViewCardModal(true);
				break;
			case "greetingcard":
				setActiveMailItemType(GREETING_CARD)
				setViewCardModal(true);
				break;
			case "giftcard":
				setActiveMailItemType(GIFT)
				setViewCardModal(true);
				break;
			case "letter":
				setActiveMailItemType(LETTER)
				setViewCardModal(true);
				break;
			default:
				setOpenSMSModal(true);
				break;

		}
	};

	const noAccessNotification = () => {
		console.log("noAccessNotification");
		if (isPaused) {
			setViewPauseModal(true);
		} else {
			setViewRegistrationModal(true);
		}
	}

	const routeLink = (path, state = null) => {

		if (state) {
			history.push({
				pathname: path,
				state: state
			});
		} else {
			history.push({
				pathname: path
			});
		}

	}

	window.mapAccessCheck = () => {
		return access;
	}

	return (
		<React.Fragment>
			{/* {access && */}
			<div className="customMapWrapper">
				{(viewCardModal && activeMailItemType != null) &&

					<CardMultiStepForm
						open={viewCardModal}
						onClose={() => { setViewCardModal(false); setActiveMailItemType(null) }}
						messageType={activeMailItemType}
						setViewCardModal={setViewCardModal}
						from={'map-finder'}
						showAllTimeSettings={true}
						// setDirectMailSave={setDirectMailSave}
						onSubmit={() => console.log("submit to map")}
						editableCampaign={null}
					/>
				}
				<GlobalModal
					open={actionModalShow}
					placement="middle"
					onClose={() => setActionModalShow(false)}>
					<ActionModalComponent />
				</GlobalModal>
				{allInfoModalShow && (
					<AllInfoModal
						isOpen={allInfoModalShow}
						title={"Contact Info"}
						hideModal={() => setAllInfoModalShow(false)}
						campaignId={null}
						data={null}
						changeUserData={() => console.log("data update")}
						from={"map-marker"}
					/>
				)}
				{/* <GlobalModal open={searchParameterShow} hideFooter className="ppc__search_modal" placement="middle" onClose={() => setSearchParameterShow(false)} title="How many neighbors do you want to select around the marker?"> */}
				{searchParameterShow && (
					<SearchParameter
						hideFooter={true}
						open={searchParameterShow}
						modalClass={"ppc__search_modal_in_map"}
						onClose={() => setSearchParameterShow(false)}
						// title="How many neighbors do you want to select around the marker?"
						title="Search Neighbours around markers"
						searchingParameter={searchingParameter}
						access={access}
					/>
				)}

				{/* <GlobalModal open={tagListShow} hideFooter placement="right" onClose={() => setTagListShow(false)} title="Tag List" className="ppm_tags__modal"> */}
				{/* </GlobalModal> */}
				{tagListShow && (
					<TagModalComponent
						hideFooter={true}
						className="ppc__search_modal update_tag_list"
						open={tagListShow}
						onClose={() => setTagListShow(false)}
						type={LOAD_TAGS}
					/>
				)}
				{excludeTagListShow && (
					<TagModalComponent
						className="ppc__search_modal"
						open={excludeTagListShow}
						// searchByTag={window.searchByTagInMapModule}
						onClose={() => setExcludeTagListShow(false)}
						type={EXCLUDE_TAGS}
						hideFooter={true}
					/>
				)}

				{/* <GlobalModal open={excludeTagListShow} hideFooter placement="right" onClose={() => setExcludeTagListShow(false)} title="Tag List" className="ppm_tags__modal">
                    <TagModalComponent searchByTag={window.searchByTagInMapModule} onClose={() => setExcludeTagListShow(false)} type={EXCLUDE_TAGS} />
                </GlobalModal> */}

				{showSelectAction && (
					<SelectAction
						modalClass="mapFinder__select_action"
						onSubmit={submitActionData}
						open={showSelectAction}
						onClose={() => setShowSelectAction(false)}
						title="Select Action"
						buttonText={"Next"}
					/>
				)}
				{sendingProcess &&
					<GlobalModal
						open={sendingProcess}
						hideFooter
						placement="middle"
						onClose={() => setSendingProcess(false)}
						title="Select Sending Process"
						className="sending_process_modal common__modal">
						<SendingProcess onSubmit={selectionSubmitProcess} />
					</GlobalModal>
				}
				{showOrderName &&
					<GlobalModal
						open={showOrderName}
						hideFooter
						placement="middle"
						title="Set List Name"
						className="set_order_modal common__modal">
						<SetOrderName onSubmit={submitOrderName} />
					</GlobalModal>
				}

				{showInstanceSend &&
					<GlobalModal
						open={showInstanceSend}
						// onSubmit={instantSendNextStep}
						closeButtonIcon={""}
						buttonIcon={<Forward />}
						buttonText="Next"
						onClose={() => setShowInstanceSend(false)}
						title="Instant Send"
						modalClass="v3__instance__modal"
						className="instance__modal updated_instance__modal"
						onSubmit={() => handleOpenSendModal()}>
						<InstanceSendComponent
							sendType={instantSendType}
							handleSendType={handleInstantSendType}
						/>
					</GlobalModal>
				}

				{showDripMode && (
					<DripModeModal
						open={showDripMode}
						onClose={() => setShowDripMode(false)}
						campaignId={campaignId}
					/>
				)}

				{/* <GlobalModal onSubmit={addContactFromSidebar} open={showContactDetails} closeButtonIcon={''} buttonText={savedContactDetails != null ? "update contact" : "save"}
                    onClose={() => setShowContactDetails(false)} title="Details" className="contact__details_modal"> */}
				{showContactDetails && (
					//  Utils.getAccountData('userId') === 1 || Utils.getAccountData('userId') === 12  ?
					<ContactDetailsV2
						open={showContactDetails}
						onClose={() => setShowContactDetails(false)}
						markerContactDetails={markerContactDetails}
						setMarkerContactDetails={setMarkerContactDetails}
						dispositionList={dispositionList}
						addSuccess={contactAddSuccess}
						title="Details"
						className="contact__details_modal global_modal_master_piece"
						campaignList={campaignList}
						dispositionInfo={dispositionInfo}
						savedContactDetails={savedContactDetails}
						contactLocation={contactLocation}
					/>
					// :
					// 	<ContactDetails
					// 	open={showContactDetails}
					// 	onClose={() => setShowContactDetails(false)}
					// 	markerContactDetails={markerContactDetails}
					// 	setMarkerContactDetails={setMarkerContactDetails}
					// 	dispositionList={dispositionList}
					// 	addSuccess={contactAddSuccess}
					// 	title="Details"
					// 	className="contact__details_modal global_modal_master_piece"
					// 	campaignList={campaignList}
					// 	dispositionInfo={dispositionInfo}
					// 	savedContactDetails={savedContactDetails}
					// 	contactLocation={contactLocation}
					// />


				)}
				{/* </GlobalModal> */}

				{openSMSModal && (
					<SmsSendModal
						data={{ id: null }}
						open={openSMSModal}
						from={"map"}
						onClose={() => setOpenSMSModal(false)}
					/>
				)}

				{openEmailModal && (
					<EmailSendModal
						data={{ id: null }}
						open={openEmailModal}
						from={"map"}
						onClose={() => setOpenEmailModal(false)}
					/>
				)}

				{openVideoMailModal && (
					<VideoEmailModal
						data={{ id: null }}
						open={openVideoMailModal}
						from={"map"}
						onClose={() => setOpenVideoMailModal(false)}
					/>
				)}

				{openVoiceMailModal && (
					<VoiceSendModal
						data={{ id: null }}
						open={openVoiceMailModal}
						from={"map"}
						onClose={() => setOpenVoiceMailModal(false)}
					/>
				)}


				<GlobalModal
					open={showDealProcess}
					closeButtonIcon={""}
					buttonText="Add to Queue to assign"
					onClose={() => setShowDealProcess(false)}
					title="Leads Process Mode"
					className="contact__details_modal">
					<LeadProcess />
				</GlobalModal>

				<form className="customMapWrapperForm customMapWrapperForm_oct_12">
					<div className="flexRow ">
						<div className="flexItem1">
							<div className="flexItem1Left">
								<h2 className="py-3">Find New Leads</h2>
								<FormGroup className="ppc__searchSelect_with_input">
									<input
										id="address"
										className="form-control pac-target-input ppc__searchSelect"
										type="textbox"
										ref={addressRef}
										placeholder="Enter a location"
										autoComplete="off"
									/>

									{/* <Autocomplete
										id="ppc__searchSelect"
										className="ppc__searchSelect"
										options={ top100Films }
										getOptionLabel={ (option) => option.title }
										renderInput={ (params) => <TextField { ...params } placeholder="Washington D.C  USA" variant="outlined" /> }
									/> */}
									<Button
										id="address_search_button"
										className="ppc__searchSelect_btn accent--bg--color">
										<Search /> Search
									</Button>
								</FormGroup>
							</div>

							<FormGroup className="ppc__searchSelect_with_input">
								<p>
									<ErrorOutline /> Enter a state, city, or
									specific address above then use our drawing
									tools to highlight your future leads.
									{/* Need help? <span>Click here</span> for a quick tutorial. */}
								</p>
							</FormGroup>
						</div>
						<div className="flexItem1 lastOne">
							<FormGroup className="ppc__searchSelect_with_input ppc__customCheckbox">
								<FormControlLabel
									control={
										<Checkbox
											checked={loadAllContacts}
											onChange={() => {
												console.log("2709", access)
												access ? handleChange(LOAD_ALL_CONTACTS, true) : noAccessNotification()
											}}
											name="checkedB"
											color="primary"
											id="load-all-contacts-in-map"
										/>
									}
									label="Load All Contacts"
								/>

								<FormControlLabel
									control={
										<Checkbox
											checked={previewDataShow}
											onChange={() =>
												access ? updatePreviewDataShow() : noAccessNotification()
											}
											name="checkedB"
											color="primary"
											id="load-all-contacts-in-map"
										/>
									}
									label="Preview Data"
								/>

								{/* <FormControlLabel
                            control={
                            <Checkbox
                                checked={searchParameterShow}
                                onChange={()=> handleChange(1) }
                                name="checkedB"
                                color="primary"
                            />
                            }
                            label="Primary"
                        /> */}

								<FormControlLabel
									control={
										<Checkbox
											checked={
												loadTagList.length > 0
													? true
													: false
											}
											onChange={() =>
												access ? handleChange(LOAD_TAGS) : noAccessNotification()
											}
											name="loadTags"
											color="primary"
										/>
									}
									label="Load Tags"
								/>

								<FormControlLabel
									control={
										<Checkbox
											checked={
												excludeTags.length > 0
													? true
													: false
											}
											onChange={() =>
												access ? handleChange(EXCLUDE_TAGS) : noAccessNotification()
											}
											name="select_action"
											color="primary"
										/>
									}
									label="Exclude Tags"
								/>

								<FormControlLabel
									control={
										<Checkbox
											checked={showDispositionSelect}
											onChange={access ? updateDispositionSearch : noAccessNotification}
											name="search_disposition"
											color="primary"
										/>
									}
									label="Search disposition"
								/>


							</FormGroup>
							{
								showDispositionSelect && (
									<ShowSelectedDisposition
										SearchMapByDisposition={IndexOld.SearchMapByDisposition}
										clearSelection={IndexOld.clearSelection}
										selectedDispositionName={
											selectedDispositionName
										}
										updateSelectedMultipleValue={
											updateSelectedMultipleValue
										}
										update={update}
										dispositionList={dispositionList}
										selectedDispositionId={
											selectedDispositionId
										}
									/>
								)
							}
						</div>
					</div>
				</form>

				<div className="customMap" style={{ height: `${deviceHeight - 200}px` }}>
					{!access && viewPauseModal && <PauseAlert onClose={() => { setViewPauseModal(false) }} />}
					{!access && viewRegistrationModal && <InformationPopup routeLink={routeLink} onClose={() => setViewRegistrationModal(false)} />}
					<GoogleMap
						bootstrapURLKeys={{
							key: process.env.REACT_APP_MAP_KEY,
							libraries: ["drawing", "places"].join(","),
						}}
						defaultCenter={initialCenter}
						defaultZoom={initialZoom}
						options={{
							mapTypeId: "hybrid",
							mapTypeControl: true,
							scaleControl: true,
							streetViewControl: false,
							rotateControl: true,
							fullscreenControl: true,
							zoomControl: true,
							gestureHandling: "greedy",
						}}
						yesIWantToUseGoogleMapApiInternals
						onGoogleApiLoaded={(google) =>
							handleApiLoaded(google)
						}></GoogleMap>
				</div>
			</div>
			{/* } */}
		</React.Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		storedCampaignFormapSidebar:
			state.mapReducer.selectedCampaignForMapSidebar,
		storedDispositionForMapSiderbar:
			state.mapReducer.selectedDispositionForMapSidebar,
		allContactPositionArray: state.mapReducer.allContactPositionArray,
		storedDispositionList: state.mapReducer.dispositionList,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchAllContactsPositionArray: (params, callback) =>
			dispatch(fetchAllContactsPositionArray(params, callback)),
		fetchUserDisposition: (params, callback) =>
			dispatch(fetchUserDispositionList(params, callback)),
		/* for contact details modal */
		_updateContactId: (params) => dispatch(_updateContactId(params)),
		_updateContactDetails: (params) =>
			dispatch(_updateContactDetails(params)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(IndexOld);
