import React, { useState, useRef, useEffect } from "react";
import { Save, SearchOutlined } from "@material-ui/icons";
import { icons } from "./icon";
import "./assignToCampaign.css";
import { getPowerDialerList } from "../../../api/enhanceData";
import GlobalModal from "../../globals/Modal/GlobalModal";
import NewLoader from "../../Common/NewLoader";
import { saveContactFromMapSidebar } from "../../../api/mapApi";

const EachCampaign = (props) => {
  const checkSelect = (id) => {
    return !!props.selectedCampaigns.includes(id);
  };

  return (
    <li>
      <div className="custom-checkbox-wrapper">
        <span
          className="custom-checkbox-icon"
          onClick={() => {
            props.selectCampaign(props.data.id);
          }}
          key={`id_${props.data.id};`}
        >
          {checkSelect(props.data.id) ? icons.check : icons.unCheck}
        </span>
        <span>{props.data.title}</span>
      </div>
    </li>
  );
};

const ModalAssignToPowerDialer = (props) => {
  const [powerDialerList, setPowerDialerList] = useState([]);
  const [submitEnable, setSubmitEnable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHaMore] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [searchText, setSearchText] = useState("");
  const powerDialers = useRef([]);

  useEffect(() => {
    getCampaignData();
    if (powerDialers.current.length > 0) {
      setPowerDialerList(powerDialers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {}, [searchText]);

  const getCampaignData = (page = currentPage) => {
    getPowerDialerList().then((res) => {
      let responseData = res.data;

      if (page === 0) {
        setPowerDialerList(responseData.data);
        powerDialers.current = responseData.data;
      } else {
        let data = [...powerDialerList, ...responseData.data];
        setPowerDialerList(data);
        powerDialers.current = responseData.data;
      }
      if (responseData.data.length >= 20) {
        setHaMore(true);
        setCurrentPage(page + 1);
      } else {
        setHaMore(false);
      }
      setLoadingMore(false);
    });
  };

  const handleRadio = (value) => {
    setSubmitEnable(true);
    // let tempSelectedCampaigns = [...selectedCampaigns]
    // if (tempSelectedCampaigns.includes(value)) {
    //   tempSelectedCampaigns.splice(tempSelectedCampaigns.indexOf(value), 1)
    // } else {
    //   tempSelectedCampaigns.push(value)
    // }

    setSelectedCampaigns([value]);
  };

  const changeSearch = (e) => {
    const searchString = e.target.value.toLowerCase();
    const filteredPowerDialerList = powerDialers.current.filter(
      (powerDialer) => {
        const condition = powerDialer.title
          .toLowerCase()
          .includes(searchString);
        if (condition) return true;
        return false;
      }
    );
    setSearchText(searchString);
    setPowerDialerList(filteredPowerDialerList);
  };

  const handleScroll = (e) => {
    if (
      hasMore &&
      !loadingMore &&
      Math.round(e.target.scrollTop + e.target.clientHeight) >=
        Math.round(e.target.scrollHeight)
    ) {
      setLoadingMore(true);
      getCampaignData();
    }
  };

  const addToPowerDialer = () => {
    const payload = {
      powerDialersId: selectedCampaigns[0],
      contactDetails: props.markerContactDetails,
    };
    saveContactFromMapSidebar(payload)
      .then((res) => {
        if (res && res.data.success) {
          window.showNotification("success", res.data.message);
          props.onClose();
          props.handleCloseModal()
        } else {
          window.showNotification(
            "error",
            "Something went wrong try again later"
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = () => {
    if (selectedCampaigns.length === 0) {
      window.showNotification("error", "Please select a power dialer");
      return;
    }
    if (selectedCampaigns) {
      setLoading(true);
      addToPowerDialer();
    } else {
      window.showNotification("ERROR", "No campaign selected");
    }
  };

  return (
    <GlobalModal
      {...props}
      title="Assign To Power Dialer"
      buttonText="Add"
      buttonIcon={<Save />}
      onSubmit={handleSubmit}
      hideFooter={false}
      modalClass="manage-tag-modal modal-assign-campaign update"
      className="manage-tag-modal modal-assign-campaign create-new-list update scroll_v2 global-medium-modal enhanceDataModal"
      submitButtonDisable={loading || !submitEnable}
    >
      {loading && (
        <NewLoader loading={loading} onlyLoader={true} size={26} message={""} />
      )}
      <div className="row">
        <div className="col-md-12 contact-to-campaign">
          <div className="campaign-search-list">
            <div
              className="campaign-search form"
              // style={{ paddingLeft: "10px" }}
            >
              <div className="form-group m-0">
                {/* <div className="m-input-icon m-input-icon--right">
                  <input
                    onChange={changeSearch}
                    value={searchText}
                    type="text"
                    className="form-control m-input customFormControlField alt"
                    placeholder="Search power dialer"
                  />
                    <SearchOutlined className="assign__searchIcon" />
                  <span className="m-input-icon__icon m-input-icon__icon--right">
                    <span>
                      <i className="flaticon-search-1" />
                    </span>
                  </span>
                </div> */}
                <div className="m-input-icon m-input-icon--right assign__tag_search_wrapper">
                    <input
                      onChange={changeSearch}
                      value={searchText}
                      type="text"
                      className="form-control m-input customFormControlField custom-popup-search"
                      placeholder="Type and search"
                    />
                    <SearchOutlined className="assign__searchIcon" />
                    <span className="m-input-icon__icon m-input-icon__icon--right">
                      <span>
                        <i className="flaticon-search-1" />
                      </span>
                    </span>
                  </div>
              </div>
            </div>
            <div className="upload-single-item-main2 upload-single-item-main">
              <ul className="awesome__scroll_bar" onScroll={handleScroll}>
                {powerDialerList.map((data, index) => {
                  return (
                    <EachCampaign
                      selectCampaign={handleRadio}
                      data={data}
                      key={index}
                      selectedCampaigns={selectedCampaigns}
                    />
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </GlobalModal>
  );
};
export default ModalAssignToPowerDialer;
