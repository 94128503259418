import React, {useEffect, useState} from "react";
import {Button} from "@material-ui/core";
import {cleanContactsActionsApi} from "../../api/mapApi";
import NewConfirmAlert from "../Common/new-alert/NewConfirmAlert";
import ModalAssignToList from "./enhanceDataAction/ModalAssignToList";
import GlobalDropdown, {GlobalDropDownButton, GlobalDropDownItem} from "../globals/Dropdown/GlobalDropdown";
import {KeyboardArrowDown} from "@material-ui/icons";
import {IconList} from "../../constants/IconList";
import ModalAssignToTag from "./enhanceDataAction/ModalAssignToTag";
import ModalListForStageAdd from "./enhanceDataAction/ModalListForStageAdd";
import ModalAssignToCampaign from "./enhanceDataAction/ModalAssignToCampaign";
import ModalAssignToPowerDialer from "./enhanceDataAction/ModalAssignToPowerDialer";
import ContactItem from "./ContactItem";
import {carrierLookupVerifyContactV1Api, getCarrierLookupCreditSettingsApi} from "../../api/enhanceData";
import {connect} from "react-redux";
import {setCarrierLookupPrice} from "../../actions/mapAction";
import {LOOKUP_TYPE_DNC_LOOKUP} from "../../constants/CoreConstants";
import BootstrapTooltip from "../globals/BootstrapTooltip";
import Icons from "./Icons";

const SkipTracingContactDetailsTab = ({ setHideFooter, data, allClean, setCarrierLookupPrice, carrierLookupPrices }) => {
    const [listData, setListData] = useState(data);
    useEffect(() => {
        setHideFooter(true);
    }, []);
    const handleCallback = (payload) => {
        if (payload.success && payload.index !== null && payload.index !== undefined){
            let newData = [...listData];
            newData.splice(payload.index, 1);
            if (!newData[0]){
                allClean();
            }
            setListData(newData);
        }
    };

    return (
        <div>
            {
                listData.map((result, index)=>(
                    <SkipTraceResult key={`skipped-item-${index}`} index={index} data={result} callback={handleCallback} setCarrierLookupPrice={setCarrierLookupPrice} carrierLookupPrices={carrierLookupPrices}/>
                ))
            }
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        carrierLookupPrices: state.mapReducer.carrierLookupPrices,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCarrierLookupPrice: (params) => dispatch(setCarrierLookupPrice(params)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SkipTracingContactDetailsTab);

const getLookupLabel = {
    EMAIL: "Email Lookup",
    NUMBER: "Number Lookup",
    DNC_STATUS: "Do-not-call Lookup",
};

const cleanNumber = (number) => {
    try{
        if(isNaN(number)){
            return "x.xx";
        }

        if (typeof number === "string") {
            number = parseFloat(number);
        }

        const parts = number.toString().split('.');
        if (parts.length === 1) {
            return number.toFixed(2).replace(/\.0*$/, '') + '.00';
        }
        const decimalPart = parts[1].length < 2 ? parts[1] + '0' : parts[1];
        return `${parts[0]}.${decimalPart}`;
    }catch(err){
        return "x.xx";
    }
};

const SkipTraceResult = ({ data: { firstName, lastName, middleName, number, email, houseNumber, city, street, state, zip, clean_contact_id }, callback, index, carrierLookupPrices, setCarrierLookupPrice }) => {
    const [openAddToListModal, setOpenAddToListModal] = useState(false);
    const [openAddToTagModal, setOpenAddToTagModal] = useState(false);
    const [openAddToStageModal, setOpenAddToStageModal] = useState(false);
    const [openAddToCampaignModal, setOpenAddToCampaignModal] = useState(false);
    const [openAddToPowerDialerModal, setOpenAddToPowerDialerModal] = useState(false);
    const [dncStatus, setDncStatus] = useState("NOT_VERIFIED");
    const creditSettingType = { CALLER_NAME: 63, CARRIER: 64, VERIFY_EMAIL: 65, DNC: 66 };
    const [doNotCall, verifiedOk] = ["DO_NOT_CALL", "VERIFIED_OK"];

    let contactNumber = number;
    let	contactEmail = email;

    if(!contactNumber){
        contactNumber = "No number available";
    };

    if(!contactEmail){
        contactEmail = "No email available";
    };
console.log(openAddToListModal)
    const renderLookupStatus = (lookupType,status) => {

        switch (status) {
            case doNotCall:
                return <BootstrapTooltip arrow placement="top-center" title="DNC Enabled">
                    <span className="d-flex align-items-center mr-2">
                        {Icons.doNotCall}
                    </span>
                </BootstrapTooltip>;
            case verifiedOk:
                return <BootstrapTooltip arrow placement="top-center" title="DNC Verified OK">
                    <span className="d-flex align-items-center mr-2">
                        {Icons.verifiedOk}
                    </span>
                </BootstrapTooltip>;
            default:
                return <div className="validate--button" onClick={async ()=>{
                    await carrierLookupVerifyContact("DNC_STATUS", contactNumber);
                }}>Check DNC</div>;
        }
    };

    const carrierLookupVerifyContact = async (validationType, query) => {
        if (!carrierLookupPrices) {
            getCarrierLookupCreditSettingsApi()
                .then((response) => {
                    if (response.data && response.data.status === "success") {
                        if (response.data && response.data.data) {
                            setCarrierLookupPrice(response.data.data);
                            alertToVerify(response.data.data, validationType, query);
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            alertToVerify(carrierLookupPrices, validationType, query);
        }
    };

    const alertToVerify = (pricingInfo,validationType, query) => {
        let price;
        if (pricingInfo) {
            if (validationType === "DNC_STATUS") {
                price = pricingInfo.find(({ type }) => type === creditSettingType["DNC"]);
                if (price) {
                    price = price.value;
                }
            }
        }

        if (price) {

            let validationParams;
            if (validationType === "DNC_STATUS") {
                validationParams = {
                    lookupType: [LOOKUP_TYPE_DNC_LOOKUP],
                    contact: {
                        number: query
                    },
                    requestModule: "CLEAN_DATA",
                };
            }

            NewConfirmAlert({
                onSubmit: () => {
                    carrierLookupVerifyContactV1Api(validationParams)
                        .then((response) => {
                            if (response) {
                                if (response && response["dnc-lookup-status"] === "REQUEST_DONE"){
                                    if (response["dnc-validation-infos"]["dncStatus"] === "YES"){
                                        setDncStatus(doNotCall);
                                    }else if (response["dnc-validation-infos"]["dncStatus"] === "NO"){
                                        setDncStatus(verifiedOk);
                                    }
                                    window.showNotification("SUCCESS", "Validated successfully");
                                }
                            } else {
                                setDncStatus("NOT_VERIFIED");
                                window.showNotification("ERROR", "Failed to validate");
                            }
                        })
                        .catch((err) => {
                            window.showNotification("ERROR", `Something went wrong while validate`);
                        });
                },
                title: `${getLookupLabel[validationType]} will cost: ${cleanNumber(price)}`,
                description: "These credits will be deducted from your balance.",
                cancelText: "Cancel",
                submitText: "Validate",
                width: "480px",
            });
        } else {
            window.showNotification("ERROR", "Please contact with system administrator");
        }
    };


    const addToCRM = () => {
        NewConfirmAlert({
            onSubmit: () => {
                const payload = {
                    cleanContactIds: [clean_contact_id],
                    filterType: "SEGMENT",
                    action: "ADD_TO_CRM",
                };

                cleanContactsActionsApi(payload).then((res) => {
                    if (res && res.success) {
                        window.showNotification("success", res.message);
                        callback({
                            success: true,
                            removeItem: clean_contact_id,
                            index: index
                        });
                    } else {
                        window.showNotification("error", "Something went wrong try again later");
                    }
                });
            },
            title: "Are your sure?",
            description: "You are about to add selected contact to CRM",
            cancelText: "Cancel",
            submitText: "Add",
            width: "480px"
        });
    };


  return (
      <div className="contact__first_steps map-finder-contact-details">
          <ContactItem label="Name" value={`${firstName} ${middleName} ${lastName}`}/>
          <ContactItem label="Number" value={contactNumber} status={(contactNumber !== "No number available") ? renderLookupStatus("DNC_STATUS", dncStatus) : <span/>}/>
          <ContactItem label="Email" value={contactEmail}/>
          <ContactItem label="Address" value={`${houseNumber} ${street}`}/>
          <ContactItem label="City" value={city}/>
          <ContactItem label="State" value={state}/>
          <ContactItem label="Zip" value={zip}/>

          <div className="d-flex justify-content-end">
              <GlobalDropdown
                  className="add__action_dropdown"
                  placement="bottomLeft"
              >
                  <GlobalDropDownButton>
                      <Button
                          className="list__settings_btn"
                          variant="contained"
                          color="primary"
                          endIcon={<KeyboardArrowDown />}
                      >
                          Actions
                      </Button>
                  </GlobalDropDownButton>
                  <GlobalDropDownItem
                      onClick={addToCRM}
                      title="Add All Contact To CRM"
                      onClickHide={true}
                      icon={IconList.addToContactSVG}
                  />
                  <GlobalDropDownItem
                      onClick={() => {
                          setOpenAddToListModal(true);
                         
                      }}
                      title="Add To List"
                      onClickHide={true}
                      icon={IconList.addToListSVG}
                  />
                  <GlobalDropDownItem
                      onClick={() => {
                          setOpenAddToCampaignModal(true);
                       
                      }}
                      title="Add To Campaign"
                      onClickHide={true}
                      icon={IconList.addToContactSVG}
                  />
                  <GlobalDropDownItem
                      onClick={() => {
                          setOpenAddToTagModal(true);
                      }}
                      title="Assign Tag"
                      onClickHide={true}
                      icon={IconList.addTagSVG}
                  />
                  <GlobalDropDownItem
                      onClick={() => {
                          setOpenAddToStageModal(true);
                      }}
                      title="Add to Pipeline Stage"
                      onClickHide={true}
                      icon={IconList.addToPipelineSVG}
                  />
                  <GlobalDropDownItem
                      onClick={() => {
                          setOpenAddToPowerDialerModal(true);
                      }}
                      title="Add To Power Dialer List"
                      onClickHide={true}
                      icon={IconList.addToContactSVG}
                  />
              </GlobalDropdown>
          </div>

          {
              openAddToListModal &&
              <ModalAssignToList
                  open={openAddToListModal}
                  onClose={() => setOpenAddToListModal(false)}
                  cleanContactId={clean_contact_id}
                  callback={callback}
                  index={index}
              />
          }

          {
              openAddToTagModal &&
              <ModalAssignToTag
                  open={openAddToTagModal}
                  onClose={() => setOpenAddToTagModal(false)}
                  cleanContactId={clean_contact_id}
                  callback={callback}
                  index={index}
              />
          }

          {
              openAddToStageModal &&
              <ModalListForStageAdd
                  open={openAddToStageModal}
                  onClose={() => setOpenAddToStageModal(false)}
                  cleanContactId={clean_contact_id}
                  callback={callback}
                  index={index}
              />
          }

          {
              openAddToCampaignModal &&
              <ModalAssignToCampaign
                  open={openAddToCampaignModal}
                  onClose={() => setOpenAddToCampaignModal(false)}
                  cleanContactId={clean_contact_id}
                  callback={callback}
                  index={index}
              />
          }

          {
              openAddToPowerDialerModal &&
              <ModalAssignToPowerDialer
                  open={openAddToPowerDialerModal}
                  onClose={() => setOpenAddToPowerDialerModal(false)}
                  cleanContactId={clean_contact_id}
                  callback={callback}
                  index={index}
              />
          }
      </div>
  );
};