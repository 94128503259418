import React from "react";
import Styles from "./ContactItem.module.css";
import Helper from "../globals/Helper";

const ContactAddress = ({ label, value, contactLocation, status=null }) => {
    return (
        <div className={Styles.contactItemWrapper}>
            <div className={Styles.contactItemLabel}>
                {label}
            </div>
            <div className={Styles.contactItemValue}>
                <div>
                    {value} 
                    {!Helper.isEmpty(contactLocation) &&
                        <div><small>Latitude: {contactLocation.latitude} | Longitude: {contactLocation.longitude}</small></div>
                    }   
                </div>
                
                {
                    status &&
                    <span>
                        {status}
                    </span>
                }
            </div>
        </div>
    );
};

export default ContactAddress;