import React from "react";
import Styles from "./ContactItem.module.css";

const ContactItem = ({ label, value, status=null }) => {
    return (
        <div className={Styles.contactItemWrapper}>
            <div className={Styles.contactItemLabel}>
                {label}
            </div>
            <div className={Styles.contactItemValue}>
                {value}
                {
                    status &&
                    <span>
                        {status}
                    </span>
                }
            </div>
        </div>
    );
};

export default ContactItem;