import React, {Fragment, useEffect} from "react";
import {Button, FormGroup, Select, TextField} from "@material-ui/core";
import Helper from "../globals/Helper";
import {DropdownHelper} from "../globals/Dropdown/DropdownHelper";
import {Add, Clear} from "@material-ui/icons";
import ContactItem from "./ContactItem";
import ContactAddress from "./ContactAddress";

const DISPOSITION_ICONS = [
    '/assets/img/disposition/APPT-lightblue.png','/assets/img/disposition/cross-black.png',
    '/assets/img/disposition/cross-red.png','/assets/img/disposition/CS-blue.png',
    '/assets/img/disposition/CS-green.png','/assets/img/disposition/NH-blue.png',
    '/assets/img/disposition/NH-purple.png','/assets/img/disposition/NH-red.png',
    '/assets/img/disposition/DND-deepblue.png','/assets/img/disposition/DND-red.png',
    '/assets/img/disposition/idea-orange.png','/assets/img/disposition/idea-green.png',
    '/assets/img/disposition/money-blue.png','/assets/img/disposition/money-green.png',
    '/assets/img/disposition/money-purple.png','/assets/img/disposition/DND-red.png',
    '/assets/img/disposition/reload-blue.png','/assets/img/disposition/reload-green.png',
    '/assets/img/disposition/reload-yellow.png','/assets/img/disposition/star-black.png',
    '/assets/img/disposition/star-green.png','/assets/img/disposition/star-purple.png',
    '/assets/img/disposition/sheriff-red.png','/assets/img/disposition/sheriff-white.png'

];

const ContactDetailsTab = ({
       addNewDisposition,
       setAddNewDisposition,
       setSelectedDispositionId,
       dispositionList,
       selectedDispositionId,
       setNewDispositionName,
       newIconLink,
       setNewIconLink,
       selectedCampaignId,
       setSelectedCampaignId,
       campaignList,
       data: {
           firstName,
           lastName,
           name,
           number,
           email,
           addressFull,
           lengthOfResidence,
           income,
           houseOwnerFlag,
           estimatedHomeValue,
           livingSquareFeet,
           financialOccupation,
           financialOccupationCode,
           subdivisionName,
           propertyPool,
           hasBoat,
           hasMotorcycle,
           hasRV,
           autoXDate,
           autoXDateSource,
           purchaseAmount,
           propertySaleDate,
           financialCreditRating,
           familyMaritalStatus,
           cleanDataInfos
       },
       renderLookupStatus,
       setHideFooter,
       contactLocation=null
}) => {

    useEffect(() => {
        setHideFooter(false);
    }, []);

    const handleCampaignChange=(event)=>{
        setSelectedCampaignId(event.target.value)
    }

    const replaceIfNil = (set, key) => {
        if((set === "No Number available" || set === "No number available" || set === "No Email Available") && cleanDataInfos){
            let matched = null;
            for (let i = 0; i < cleanDataInfos.length; i++){
                if (cleanDataInfos[i].firstName.trim() === firstName.trim()){
                    matched = cleanDataInfos[i];
                    break;
                }
            }
            if (matched && matched[key]){
                return matched[key];
            }
        }
        return set;
    }

    return (
        <Fragment>
            <div className="contact__first_steps map-finder-contact-details">
                <ContactItem label="Name" value={name}/>
                <ContactItem label="Number" value={replaceIfNil(number, "number")} status={
                    <span className="d-flex">
                        {renderLookupStatus("number-validation-status")}
                        {renderLookupStatus("dnc-state")}
                    </span>
                }/>
                <ContactItem label="Email" value={replaceIfNil(email, "email")} status={
                    <span>
                        {renderLookupStatus("email-validation-status")}
                    </span>
                }/>
                <ContactAddress label="Address" contactLocation={contactLocation} value={addressFull}/>
                {!Helper.isEmpty(lengthOfResidence) &&
                    <ContactItem label="Length of residence" value={lengthOfResidence}/>
                }
                {!Helper.isEmpty(income) &&
                    <ContactItem label="Income" value={income}/>
                }
                {!Helper.isEmpty(houseOwnerFlag) &&
                    <ContactItem label="Home owner flag" value={houseOwnerFlag}/>
                }
                {!Helper.isEmpty(estimatedHomeValue) &&
                    <ContactItem label="Estimated home value" value={estimatedHomeValue}/>
                }
                {!Helper.isEmpty(livingSquareFeet) &&
                    <ContactItem label="Living square feet" value={livingSquareFeet}/>
                }
                {!Helper.isEmpty(financialOccupation) &&
                    <ContactItem label="Occupation" value={financialOccupation}/>
                }
                {!Helper.isEmpty(financialOccupationCode) &&
                    <ContactItem label="Occupation Code" value={financialOccupationCode}/>
                }
                {!Helper.isEmpty(subdivisionName) &&
                    <ContactItem label="Subdivision Name" value={subdivisionName}/>
                }
                {!Helper.isEmpty(propertyPool) &&
                    <ContactItem label="Pool" value={propertyPool}/>
                }
                {!Helper.isEmpty(hasBoat) &&
                    <ContactItem label="Boat" value={hasBoat}/>
                }
                {!Helper.isEmpty(hasMotorcycle) &&
                    <ContactItem label="Motorcycle" value={hasMotorcycle}/>
                }
                {!Helper.isEmpty(hasRV) &&
                    <ContactItem label="RV" value={hasRV}/>
                }
                {!Helper.isEmpty(autoXDate) &&
                    <ContactItem label="Auto XDate" value={autoXDate}/>
                }
                {!Helper.isEmpty(autoXDateSource) &&
                    <ContactItem label="Auto XDate Source" value={autoXDateSource}/>
                }
                {!Helper.isEmpty(purchaseAmount) &&
                    <ContactItem label="Sale amount" value={purchaseAmount}/>
                }
                {!Helper.isEmpty(propertySaleDate) &&
                    <ContactItem label="Sale date" value={propertySaleDate}/>
                }
                {!Helper.isEmpty(financialCreditRating) &&
                    <ContactItem label="Financial credit rating" value={financialCreditRating}/>
                }
                {!Helper.isEmpty(familyMaritalStatus) &&
                    <ContactItem label="Marital status" value={familyMaritalStatus}/>
                }
            </div>
            <div className="contact__first_steps alt v3__contact_first_steps">
                {!addNewDisposition &&

                    <div className="twoColumns d-flex align-items-end justify-content-between">
                        <FormGroup className="d-flex align-items-center">
                            <label className="dark_blue_color font-500">Dispositions</label>
                            <DropdownHelper
                                datalist={dispositionList}
                                noneFieldRequired={true}
                                noneFieldTitle="Select Disposition"
                                className=""
                                mapping={{ title: "name" }}
                                menuItemClassName=""
                                updateSelectedData={setSelectedDispositionId}
                                selectedValue={selectedDispositionId}
                                parentDivClassName="mpf-select"
                            />
                        </FormGroup>
                        <Button className="ppc__blue_btn accent--bg--color button--font" onClick={()=>{
                            setSelectedDispositionId('');
                            setAddNewDisposition(true);
                        }}><Add/>Add New</Button>
                    </div>
                }
                {addNewDisposition &&
                    <div>
                        <div className="address_search">
                            <input id="address" className="form-control" type="test" placeholder="Add Disposition Name" autoComplete="off" onChange={(e)=>{
                                setNewDispositionName(e.target.value);
                            }}/>
                            <span className="add__clearIcon" onClick={() =>{
                                setNewDispositionName('');
                                setNewIconLink('');
                                setAddNewDisposition(false);
                            }}><Clear /></span>
                        </div>
                        <div className="images__wrapper">
                            {
                                DISPOSITION_ICONS.map((image, index)=>(
                                    <img src={`${process.env.REACT_APP_APP_URL}${image}`} className={`img-thumbnail ${image===newIconLink ? 'selected-new-disposition-new-contact' : ''}`} alt="disposition-img"
                                     onClick={()=>{
                                         setNewIconLink(image);
                                     }}/>
                            )) }
                        </div>
                    </div>
                }
                <FormGroup className="d-flex align-items-center">
                    <label className="dark_blue_color font-500">Campaign</label>
                    <Select
                        native
                        value={selectedCampaignId}
                        onChange={handleCampaignChange}
                        className="mpf-select"
                        name="select_option">
                        <option aria-label="" value="" >Select Campaign</option>
                        {
                            campaignList != null && campaignList.length>0 && campaignList.map((campaign, index)=>(
                            <option value={campaign.id}>{campaign.title}</option>
                        ))}

                    </Select>
                </FormGroup>
            </div>
        </Fragment>
    );
};

export default ContactDetailsTab;